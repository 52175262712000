<div class="mx-5 my-4">
   <p class="mat-h1">{{ 'HUB' | translate }}</p>
   <p class="mx-4">{{ 'HUB/txt1' | translate }}<br>{{ 'HUB/txt2' | translate }}</p>
</div>
<div class="colored-background pt-5">
  <mat-card class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
     <mat-form-field class="col-md-6">
       <input matInput #search type="text"  autocapitalize="off" spellcheck="off"
              placeholder="{{ 'NEW-DELIVERY-REQUEST/text/delivery-address' | translate }}"
              id="hubAddress"
              [(ngModel)]="hub.address"
              (change)="convertAddressToLatLng()">
     </mat-form-field>
     <div class="col-md-3 d-flex flex-row p-0">
       <mat-form-field class="col-md-6">
         <input matInput placeholder="latitude"
                id="latitudeAddress"
                [(ngModel)]="hub.lat"
                (change)="convertLatLngToAddress()">
       </mat-form-field>
       <mat-form-field class="col-md-6">
         <input matInput placeholder="longitude"
                id="longitudeAddress"
                [(ngModel)]="hub.lng"
                (change)="convertLatLngToAddress()">
       </mat-form-field>
     </div>
     <mat-form-field class="col-md-6">
       <input matInput placeholder="Nome do Hub" [(ngModel)]="hub.name">
     </mat-form-field>
    <mat-form-field *ngIf="role !== 5" class="col-md-6">
      <mat-select  placeholder="Transportadora" [(ngModel)]="hub.shippingCompany"  >
        <mat-option *ngFor="let elem of transportadora" [value]="elem.id"  >
          {{ elem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button color="accent"  mat-raised-button class="col-md-4" (click)="createHub()">Criar HUB</button>
  </mat-card>
</div>
