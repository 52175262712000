import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-mdr-chart',
    templateUrl: './mdr-chart.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class MdrChartComponent implements OnInit {
    chart: Chart;
    noData = false;

    constructor(public translate: TranslateService) { }

    ngOnInit() { }

  createChart(data: any) {
        if (data.length > 0) {
            this.noData = false;
            const series = [];
            data.forEach(element => {
                let status = element.status;
                if (element.status === '' || element.status === null) {
                    status = 'Sem status';
                }
                series.push({ name: status, type: undefined, data: [ element.count] });
            });
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                  type: 'category',
                  labels: {
                    format: 'Shipping Company'
                  }
                },
              tooltip: {
                formatter: function() {
                  return '<b>' + this.series.name + '</b>: ' + this.y;
                }
              },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: series
            });

        } else {
            this.noData = true;
        }
    }
}
