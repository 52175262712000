<div class="mx-5 my-4 d-flex bd-highlight">
  <div class="p-2 flex-grow-1 bd-highlight">
    <p class="mat-h1">Configurações</p>
  </div>
</div>

<div class="colored-background pt-5 pb-3">
  <mat-card class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <div fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0"  class="full-width">
      <div fxFlex="45%">
        <div fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div fxFlex="50%">
            <mat-card-header>
              <mat-card-title>{{ 'SELECT-COLOR' | translate }}</mat-card-title>
              <mat-card-subtitle>{{ 'SELECT-COMPANY-COLOR' | translate }}</mat-card-subtitle>
            </mat-card-header>
          </div>
          <div fxFlex="50%">
            <span (colorPickerChange)="onPrimaryChange($event)" [colorPicker]="materialCssVarsService.primary"
              [cpOKButton]="false" [cpOutputFormat]="'hex'" [style.background]="color">
              <button mat-flat-button color="primary" class="full-width">{{ 'EDIT-COLOR' | translate }}</button>
            </span>
          </div>
        </div>
      </div>
      <div fxFlex="45%">
        <div fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div fxFlex="50%">
            <mat-card-header>
              <mat-card-title>{{ 'SELECT-LOGO' | translate }}</mat-card-title>
              <mat-card-subtitle>{{ 'SELECT-COMPANY-LOGO' | translate }}</mat-card-subtitle>
            </mat-card-header>
          </div>
          <div fxFlex="50%">
            <form [formGroup]="topicForm" novalidate class="full-width">
              <mat-form-field class="full-width">
                <ngx-mat-file-input type="file" formControlName="image" class="full-width"
                  valuePlaceholder="Nenhum arquivo selecionado" required></ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
                <mat-error *ngIf="topicForm.get('image').hasError('required')">
                  {{ 'SELECT-IMAGE' | translate }}
                </mat-error>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>

      <div fxFlex="10%" fxLayoutAlign="center center">
        <button mat-flat-button color="primary" (click)="setConfigurations()">{{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </mat-card>
</div>