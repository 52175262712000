<div class="mx-5 my-4">
  <span class="mat-h1">{{ 'DR_OPTIMIZER/title' | translate }}</span>
</div>
<div *ngIf="dataSource.data.length > 0" class="colored-background table-background">
   <div class="mx-5">
      <mat-form-field  class="my-3 pt-1 pl-4 rounded-corners col-md-8" floatLabel="never">
         <input matInput  [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{ 'DR_OPTIMIZER/filter' | translate }}">
      </mat-form-field>
      <table mat-table #table  [dataSource]="dataSource" matSort>
         <p>{{ 'DR_OPTIMIZER/list' | translate }}o</p>

         <ng-container matColumnDef="select">
            <th mat-header-cell  [ngClass]="'customWidthClass'" *matHeaderCellDef>
               <mat-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="selection.hasValue() && isAllSelected()"
                             [indeterminate]="selection.hasValue() && !isAllSelected()">
               </mat-checkbox>
            </th>
            <td mat-cell [ngClass]="'customWidthClass'" *matCellDef="let row">
               <mat-checkbox (click)="$event.stopPropagation()"
                             (change)="$event ? selection.toggle(row) : null"
                             [checked]="selection.isSelected(row)">
               </mat-checkbox>
            </td>
         </ng-container>

         <ng-container matColumnDef="idDRidentifier">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/id' | translate }} </th>
           <td mat-cell *matCellDef="let element"> {{element.idDRidentifier}} </td>
         </ng-container>

         <ng-container matColumnDef="deliveryAddress">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/address' | translate }}</th>
           <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
         </ng-container>

         <ng-container matColumnDef="site">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/site' | translate }} </th>
           <td mat-cell *matCellDef="let element"> {{element.site}} </td>
         </ng-container>

         <ng-container matColumnDef="dateScheduling">
           <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DR_OPTIMIZER/date' | translate }}  </th>
           <td mat-cell *matCellDef="let element"> {{ element.dateScheduling | date: "dd/MM/yy, h:mm a"}} </td>
         </ng-container>

         <ng-container matColumnDef="createdBy">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/created' | translate }}</th>
           <td mat-cell *matCellDef="let element">{{element.createdAt | date : 'd/M/yy H:mm'}}</td>
         </ng-container>

         <ng-container matColumnDef="client">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/table/client' | translate }}</th>
           <td mat-cell *matCellDef="let element">{{element.client}}</td>
         </ng-container>

         <ng-container matColumnDef="ovList">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/table/ovList' | translate }}</th>
           <td mat-cell *matCellDef="let element" matTooltipShowDelay="0" matTooltipPosition="above" [matTooltip]="element.ovList">{{element.salesOrders}}</td>
         </ng-container>

         <ng-container matColumnDef="uf">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/table/uf' | translate }}</th>
           <td mat-cell *matCellDef="let element">{{element.uf}}</td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
   </div>
   <div class="d-flex justify-content-between m-3 pb-3">
      <button class="button" mat-raised-button color="accent" (click)="openDialog()">{{ 'DR_OPTIMIZER/create' | translate }}</button>
      <!-- <button class="primary-button" mat-raised-button (click)="createConsolidationMDR()">Criar consolidação</button> -->
      <button class="button" color="warn" mat-raised-button (click)="unmakeDR()">{{ 'DR_OPTIMIZER/undo' | translate }}</button>
   </div>
</div>

<div *ngIf="dataSource.data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'DR_OPTIMIZER/empty' | translate }}</p>
</div>
