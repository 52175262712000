<h1 mat-dialog-title>{{ 'DR_BOOKING/dialog/status' | translate }}: {{ 'DR_BOOKING/dialog/status/'+data.status.status | translate }}</h1>
<div mat-dialog-content>
  <p *ngFor="let e of data.status.error">{{e}}</p>
  <p *ngFor="let e of data.status.warning">{{e}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickBack()">{{ 'DR_BOOKING/dialog/btn/back' | translate }}</button>
  <span></span>
  <button mat-button class="primary-button" (click)="onClickOk()" [disabled]="data.status.status === 'invalid'" cdkFocusInitial>{{ 'DR_BOOKING/dialog/btn/ok' | translate }}</button>
</div>
