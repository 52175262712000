import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-mdr-pie-chart',
    templateUrl: './mdr-pie-chart.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class MdrPieChartComponent implements OnInit {
    chart: Chart;
    noData = false;

    constructor(public translate: TranslateService) { }
    ngOnInit() { }

    createChart(data: any) {
        if (data.length > 0) {
            this.noData = false;
            const series = [];
            data.forEach(element => {
                let shippingCompany = element.shippingCompany;
                if (element.shippingCompany === '' || element.shippingCompany === null) {
                    shippingCompany = 'Sem transportadora associada';
                }
                series.push({ name: shippingCompany, y: element.count });
            });
            this.chart = new Chart({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                            distance: -50,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 4
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'MDRS',
                    colorByPoint: true,
                    type: undefined,
                    data: series
                }]
            });
        } else {
            this.noData = true;
        }
    }

}
