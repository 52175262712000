import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../environments/environment';

const base_url: string = environment.apiUrl;

export enum ACESSO { LIBERADO = 'liberado'
                   , SEM_ACESSO = 'sem acesso'
                   , BLOQUEADO = 'bloqueado'
                   , SEM_CHAVE = 'sem chave'
} // de backend/app/entity/Site.ts
export enum INFRA { OK = 'ok'
                  , NOK = 'nok'
} // de backend/app/entity/Site.ts
export enum ESTRUTURA { EX = 'existente'
                      , NE = 'nao existente'
} // de backend/app/entity/Site.ts
export enum TIPO { ROOF_TOP = 'roof top'
                 , GREEN_FIELD = 'green field'
                 , ED_RESIDENCIAL = 'ed. residencial'
                 , ED_COMERCIAL = 'ed. comercial'
                 , BTS_OUTDOOR = 'bts outdoor'
                 , BTS_INDOOR = 'bts indoor'
                 , CONTEINER = 'conteiner'
                 , SENCILIO = 'sencilio'
} // de backend/app/entity/SiteTipo.ts

@Injectable()
export class SiteService {
  public token: string;
  private uid = 'SiteService-';

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  getEnums(): any {
    const enums = {
      acesso: [],
      infra: [],
      estrutura: [],
      tipo: []
    };
    const prefix = 'SITE_INVESTIGATION/enum/';
    enums.acesso = Object.keys(ACESSO).map( (el) => ({ value: ACESSO[el], show: prefix + 'ACESSO/' + el }));
    enums.infra = Object.keys(INFRA).map( (el) => ({ value: INFRA[el], show: prefix + 'INFRA/' + el }));
    enums.estrutura = Object.keys(ESTRUTURA).map( (el) => ({ value: ESTRUTURA[el], show: prefix + 'ESTRUTURA/' + el }));
    enums.tipo = Object.keys(TIPO).map( (el) => ({ value: TIPO[el], show: prefix + 'TIPO/' + el }));
    return enums;
  }

  read(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'site', {headers: header}).map( (response) => {
      return response;
    });
  }

  readById(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'site/' + id, {headers: header}).map( (response) => {
      return response;
    });
  }

  delete(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const options = {
      headers: header,
      body: { id: id }
    };
    return this.http.delete(base_url + 'site', options).map( (response) => {
      return response;
    });
  }

  update(site: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.put(base_url + 'site', site, {headers: header}).map( (response) => {
      return response;
    });
  }

  create(site: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'site', site, {headers: header}).map( (response) => {
      return response;
    });
  }

  getShippingCompanys(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if ( (this.uid + 'getShippingCompanys') in localStorage ) {
      return new Observable((observer) => {
        observer.next(JSON.parse(localStorage.getItem(this.uid + 'getShippingCompanys')));
      });
    } else {
      return this.http.get(base_url + 'site/token/shippingcompanys', {headers: header}).map( (response) => {
        localStorage.setItem(this.uid + 'getShippingCompanys', JSON.stringify(response));
        return response;
      });
    }
  }

  getShippingCompanyUsers(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'site/token/shippingcompanys/users', {headers: header}).map( (response) => {
      return response;
    });
  }

  public sendLSPEmail(siteId: number, users: any[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'site/token/email',
                         {siteId: siteId, users: users},
                         {headers: header}).map((response) => {
      return response;
    });
  }

  createToken(opts: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    const formData: FormData = new FormData();
    if ( 'file' in opts && opts.file ) {
      formData.append('file', opts.file, opts.file.name);
    }
    const param = new HttpParams().set('id', opts.id)
                                  .append('lspid', opts.lspid)
                                  .append('visitadatahora', opts.visitadatahora.toISOString());

    return this.http.post(base_url + 'site/token', formData, {
      headers: header,
      params: param,
      reportProgress: true
    }).map( (response) => {
      return response;
    });
  }

  readWithToken(siteid: number, accesstoken: string): Observable<any> {
    const query = '?accesstoken=' + accesstoken + '&siteid=' + siteid;
    return this.http.get(base_url + 'site/token/' + siteid + query).map( (response) => {
      return response;
    });
  }

  updateFiles(siteid: number, files: any[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    const formData: FormData = new FormData();
    files.forEach( (el) => {
      formData.append('file', el.file, el.field);
    });
    const param = new HttpParams().set('siteid', String(siteid));

    return this.http.post(base_url + 'site/files', formData, {
      headers: header,
      params: param,
      reportProgress: true
    }).map( (response) => {
      return response;
    });
  }

  updateFilesWithToken(siteid: number, accesstoken: string, files: any[]): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach( (el) => {
      formData.append('file', el.file, el.field);
    });
    const param = new HttpParams().set('siteid', String(siteid)).append('accesstoken', accesstoken);

    return this.http.post(base_url + 'site/token/files', formData, {
      params: param,
      reportProgress: true
    }).map( (response) => {
      return response;
    });
  }

  updateWithToken(siteid: number, accesstoken: string, site: any): Observable<any> {
    const query = '?accesstoken=' + accesstoken + '&siteid=' + siteid;
    return this.http.put(base_url + 'site/token' + query, site).map( (response) => {
      return response;
    });
  }

  approve(siteid: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.put(base_url + 'site/approve', { id: siteid }, {headers: header}).map( (response) => {
      return response;
    });
  }

  getSiteID(idZVSD1: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'site/fromZVSD1/' + idZVSD1, {headers: header});
  }

  getSiteFromCode(code: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'site/fromCODE/' + code, {headers: header});
  }

  getPDF(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'site/pdf/' + id, {
      headers: header,
      responseType: 'blob'
    });
  }
}
