<h1 mat-dialog-title>{{ 'PRICE_MAKER_TABLE/table/id' | translate }}: {{data.pickupID}}</h1>
<div mat-dialog-content>
   <mat-form-field style="width: 500px">
      <mat-label>{{ 'TOWER_CONTROL/table/occurrence' | translate }}</mat-label>
      <input matInput [(ngModel)]="occurrence.text">
   </mat-form-field>
</div>
<div mat-dialog-actions>
   <button mat-button (click)="onClickBack()" cdkFocusInitial>{{ 'PRICE_MAKER_TABLE/dialog/btn/back' | translate }}</button>
   <button mat-raised-button class="primary-button" (click)="onClickSave()">{{ 'PRICE_MAKER_TABLE/dialog/btn/save' | translate }}</button>
</div>
