import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {OVService} from '../../providers/ov.service';
import {ERBService} from '../../providers/erb.service';
import {OV} from '../../models/ov';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DR} from '../../models/dr';
// import { parseString } from 'xml2js';
import {NfeService} from '../../providers/nfe.service';
import {NF} from '../../models/nfe';
import {HUB} from '../../models/hub';
import {HubService} from '../../providers/hub.service';
import {SiteService,
        ESTRUTURA,
        ACESSO} from '../../providers/site.service';
import {ExcelService} from '../../misc/export-xlsx/export-xlsx';
import { ClientUserService } from '../../providers/client-user.service';

declare var google: any;

@Component({
  selector: 'app-new-delivery-request',
  templateUrl: './new-delivery-request.component.html',
  styleUrls: ['./new-delivery-request.component.scss']
})

export class NewDeliveryRequestComponent implements OnInit {
  onGoingRequest = false;
  public dr: DR = new DR();
  uploaded = false;
  displayedColumnsSearched = ['select', 'mdr'];
  dataSource = new MatTableDataSource<any>();
  inputOVs: number;
  inputNFs: string;
  ovOrNf = false;
  opened: any;
  checkedColect: any;
  allValid = false;
  minDate = new Date();
  displayedColumnsOV = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
  displayedColumnsNF = ['invoiceNumber', 'client', 'materials'];
  dataSourceOV = new MatTableDataSource<OV>();
  dataSourceNF = new MatTableDataSource<NF>();
  displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'pickupAddress', 'site'];
  dataSourceDR = new MatTableDataSource<DR>();
  role: number;
  selection = new SelectionModel<any>(true, []);
  newDrList: DR[] = [];
  hubList: HUB[];
  showSiteInfo = false;
  siteSelected: any;
  site = {
    'color' : '',
    'tooltips': {
      'grey': 'site não existe',
      'yellow-1': 'site não aprovado',
      'yellow-2': 'site não liberado',
      'yellow-3': 'site não aprovado e não liberado',
      'green': 'site aprovado',
      'undefined': ''
    },
    'icons' : {
      'grey': 'fa-question-circle',
      'yellow-1': 'fa-exclamation-triangle',
      'yellow-2': 'fa-exclamation-triangle',
      'yellow-3': 'fa-exclamation-triangle',
      'green': 'fa-check',
      'undefined': ''
    },
    'classes' : {
      'grey' : 'icon-grey',
      'yellow-1': 'icon-yellow',
      'yellow-2': 'icon-yellow',
      'yellow-3': 'icon-yellow',
      'green': 'icon-green',
      'undefined': ''
    }
  };
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  clientList: string[] = [];

  @ViewChild('search2', {static: false})
  public searchElement2Ref: ElementRef;
  public paste_input = '';

  public map_show = false;
  public map_data = null;
  public red_marker = {
    url: './assets/images/pin_red.png',
    scaledSize: {
      width: 35,
      height: 40
    }
  };
  public blue_marker = {
    url: './assets/images/pin.png',
    scaledSize: {
      width: 35,
      height: 40
    }
  };

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader, public ovService: OVService,
              public router: Router, public drService: DrService, public nfService: NfeService,
              public excelService: ExcelService, public siteService: SiteService,
              private clientUserService: ClientUserService,
              public snackBar: MatSnackBar, public hubService: HubService,
              public erbService: ERBService) {
    this.dataSource.sort = this.sort;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = currentUser.user.role;
    if (this.role === 1) {
      this.minDate = new Date('1970-01-01');
    }
    this.hubService.getAll().subscribe( (data) => {
      this.hubList = data;
    });
    this.clientUserService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });

  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElement2Ref.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        // console.log('Place Changed');
        const place = autocomplete.getPlace();
        this.dr.deliveryAddress = place.formatted_address;
        this.dr.lat = place.geometry.location.lat();
        this.dr.lng = place.geometry.location.lng();
        // console.log(this.dr);
      });
    });
  }

  fillFromClipboard(e) {
    const DMStoDD = (str: string): number => {
      const parts = str.split(/[°'"]/).map((part) => {
        return +part.replace(',', '.');
      });

      const decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
      return parts[0] + decimal;
    };

    const input = e.clipboardData.getData('text');
    const splitted = input.split('\t');
    // this.salesOrders = splitted[1];
    this.dr.deliveryAddress = splitted[6];
    this.dr.lat = +DMStoDD(splitted[25]);
    this.dr.lng = +DMStoDD(splitted[26]);
    this.dr.receiverName = splitted[9];
    this.dr.receiverPhone = parseInt(splitted[10], 10);
    this.dr.dateScheduling = new Date(splitted[3]);
    this.dr.site = splitted[4];
    this.dr.infraType = splitted[11];
    this.dr.unificationId = splitted[0];
    this.dr.obs = splitted[12];

    setTimeout(() => {
      this.paste_input = '';
    }, 200);
  }

  toggleMap() {
    if (this.map_show) {
      this.map_show = false;
    } else {
      if (this.dr.lat && this.dr.lng) {
        this.erbService.getNearERB(this.dr.lat, this.dr.lng).subscribe((data) => {
          this.dr.lat = +this.dr.lat;
          this.dr.lng = +this.dr.lng;

          this.map_data = data;
          this.map_show = true;
        });
      }
    }
  }

  convertAddressToLatLng() {
    // setTimeout(() => {}, 8000);
    // const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const addr = this.dr.deliveryAddress;
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = lat;
        this.dr.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  convertDRAddressToLatLng(dr: DR): boolean {
    const error = false;
    const geocoder = new google.maps.Geocoder();
    const addr = dr.deliveryAddress;
   // console.log(addr);
    return geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        dr.deliveryAddress = results[0].formatted_address;
        dr.lat = results[0].geometry.location.lat();
        dr.lng = results[0].geometry.location.lng();
        return true;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        return false;
      }
    });
  }

  convertAddressToLatLngXml(addr): any {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // console.log('true');
        return true;
      } else {
        // console.log('false');
        return false;
      }
    });
  }

  convertLatLngToAddressFromUpload(latInp: number, lngInp: number) {
    if (latInp === null || lngInp === null) {
      return {resp: false, data: null};
    }
    const lat = latInp;
    const lng = lngInp;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        return {resp: true, data: results[0].formatted_address};
      } else {
        this.snackBar.open('Não foi possível converter uma ou mais coordenadas em um endereço', 'Ok', {duration: 2000});
        return {resp: false, data: null};
      }
    });
  }

  convertLatLngToAddress() {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  addNewItem(): void {
    if ( this.ovOrNf ) { // item é NF
      this.addNewNF();
    } else { // item é OV
      this.addNewOV();
    }
  }

  addNewOV(): void {
    this.selection.selected.forEach(el => {
      if ( this.dataSourceOV.data.indexOf(el) < 0) {
        this.dataSourceOV.data.push(el);
      }
    });

    this.dataSourceOV = new MatTableDataSource<OV>(this.dataSourceOV.data);
  }

  addNewNF(): void {
    this.selection.selected.forEach(el => {
      if ( this.dataSourceNF.data.indexOf(el) < 0) {
        this.dataSourceNF.data.push(el);
      }
    });

    this.dataSourceNF = new MatTableDataSource<NF>(this.dataSourceNF.data);
  }

  openFile(fileupload: any) {
    const input = fileupload.target;
    let error = false;
    // console.log(fileupload);
    this.newDrList = [];
    const ovList = [];
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(fileupload).subscribe( (data) => {
          data.forEach( (el, i, arr) => {
            const drAux = new DR();
            try {
              drAux.pickupAddress = el.pickup_address;
              drAux.deliveryAddress = el.delivery_address;
              drAux.site = el.site;
              drAux.receiverName = el.receiver_name;
              drAux.receiverPhone = el.receiver_phone;
              drAux.supervisorName = el.supervisor_name;
              drAux.supervisorPhone = el.supervisor_phone;
              drAux.infraType = el.infra;
              drAux.obs = el.obs;
              drAux.dateScheduling = el.dateScheduling;
              drAux.status = el.ov;
              // console.log(el.ov);
              // console.log(drAux.status);

              if ( this.convertDRAddressToLatLng(drAux) === false ) {
                this.snackBar.open('Endereço inválido', 'Ok', {duration: 2000});
                error = true;
              }
             // console.log(drAux.pickupAddress);
              if (drAux.pickupAddress.toLowerCase() !== 'local') {
                drAux.pickupDifAddress = true;
              } else {
                drAux.pickupAddress = null;
              }
             // console.log(drAux.pickupAddress);

              this.newDrList.push(drAux);
              String(drAux.status).split(',').forEach( part => {
                ovList.push(+part.trim());
              });
            } catch (ex) {
              // console.log('erro ao ler arquivo xlsx ',ex);
              this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
            }
            this.uploaded = true;
            this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);

            // this.ovService.validateOVs(ovList).subscribe( (isValid) => {
              // console.log(isValid);
              // if( isValid === false || error === true ){
              //   this.uploaded = true;
              //   //console.log(isValid);
              //   //console.log(error);
              //   this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
              // } else {

          });
        });
      } else { // arquivo xml
        const reader = new FileReader();
        // reader.onload = () => {
        //   parseString(reader.result, (err, result) => {
        //     // drAux.deliveryAddress = result.
        //     result.Workbook.Worksheet[0].Table[0].Row.forEach( (el, i, arr) => {
        //       if (i === 0) { // pula a primeira linha
        //         return;
        //       }
        //       const drAux = new DR();
        //       // console.log(el);
        //       drAux.pickupAddress = el.Cell[0].Data[0]._; // Ver se é nullo
        //       if (this.convertAddressToLatLngXml(drAux.pickupAddress) === false) {
        //         this.snackBar.open('Endereço invalido', 'Ok', {duration: 2000});
        //         // console.log(drAux.pickupAddress);
        //         error = true;
        //       }
        //       drAux.deliveryAddress = el.Cell[1].Data[0]._;
        //       if (this.convertAddressToLatLngXml(drAux.deliveryAddress) === false) {
        //         this.snackBar.open('Endereço invalido', 'Ok', {duration: 2000});
        //         // console.log(drAux.pickupAddress);
        //         error = true;
        //       }
        //       drAux.site = el.Cell[2].Data[0]._;
        //       drAux.receiverName = el.Cell[3].Data[0]._;
        //       drAux.receiverPhone = el.Cell[4].Data[0]._;
        //       drAux.supervisorName = el.Cell[5].Data[0]._;
        //       drAux.supervisorPhone = el.Cell[6].Data[0]._;
        //       drAux.infraType = el.Cell[7].Data[0]._;
        //       drAux.obs = el.Cell[8].Data[0]._;
        //       drAux.dateScheduling = el.Cell[9].Data[0]._;
        //       drAux.status = el.Cell[10].Data[0]._;
        //       // const aux = el.Cell[0].Data[0]._;
        //       // TODO Insert OVs in ov List and check if only the id works to change
        //       this.newDrList.push(drAux);
        //       // drAux.deliveryAddress = el.

        //       drAux.status.split(',').forEach( part => {
        //         ovList.push(part.trim());
        //       });
        //     });

        //     this.ovService.validateOVs(ovList).subscribe( (isValid) => {
        //       if ( isValid === false || error === false ) {
        //         this.uploaded = false;
        //         this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
        //       } else {
        //         this.uploaded = true;
        //         this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);
        //       }
        //     });

        //   });
        // };
        reader.readAsText(input.files[index]);
      }
    }
    // console.log(this.newDrList);
    // console.log(this.dataSourceDR);
  }

  private validateData(): boolean {
    this.dr.deliveryAddress = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
    if ( this.dr.deliveryAddress === '' && (this.dr.lat === null || this.dr.lng === null )) {
      return false;
    }
    return true;
  }

  validate(): void {
    this.newDrList.forEach((elem) => {
      const ovs = String(elem.status).split(',');
      for (let i = 0; i < ovs.length; i++) {
        if (ovs[i] !== undefined) {
          ovs[i] = ovs[i].trim();
        }
      }
      ovs.forEach((k) => {
        const newOv = new OV();
        newOv.salesDocument = k;
        elem.ovList.push(newOv);
      });

      // valida OVs recebidas do arquivo
      this.ovService.validateOVs(ovs).subscribe( (isValid) => {
        this.allValid = isValid;
        if ( isValid === false ) {
          // OVs invalidas segundo backend
          elem.idDRidentifier = 'red';
          this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
          this.dataSourceDR =  new MatTableDataSource<DR>(this.newDrList);
        } else if (elem.pickupDifAddress === true) {
          // valida HUB recebido do arquivo
          this.ovService.validateHub(elem.pickupAddress).subscribe( (resp) => {
            if (resp !== undefined && resp.text !== 0) {
              // HUB é válido
              elem.pickupAddress = resp.text;
            } else {
              // HUB é inválido
              this.allValid = false;
              elem.idDRidentifier = 'red';
              this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
              this.dataSourceDR =  new MatTableDataSource<DR>(this.newDrList);
            }
          });
        }
      });
    });
  }

  create(): void {
    if (this.validateData()) {
      this.dr.receiverPhone = this.dr.receiverPhone.valueOf();
      this.dr.supervisorPhone = this.dr.supervisorPhone.valueOf();
      // array de OVs
      this.dr.ovList = new Array<OV>();
      this.dataSourceOV.data.forEach(el => {
        this.dr.ovList.push(el);
      });
      // array de NFs
      this.dr.nfList = new Array<NF>();
      this.dataSourceNF.data.forEach(el => {
        this.dr.nfList.push(el);
      });
      // impede novo click do botão
      this.onGoingRequest = true;
      this.drService.insert(this.dr).subscribe( (response) => {
        // reseta botão
        this.onGoingRequest = false;
        const dialogRef = this.dialog.open(OverviewExampleDialogComponent, {
          data: {
            id: response.idDRidentifier
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          this.router.navigate(['delivery-optimizer']);
        });
      }, (error) => {
        // reseta botão
        this.onGoingRequest = false;
        this.snackBar.open('Erro ao criar DR: uma ou mais OVs já estão em uso', 'Ok', {duration: 4000});
        // reseta tabelas
        this.dataSource.data = [];
        this.dataSourceOV.data = [];
        this.dataSourceNF.data = [];
        this.selection.clear();
      });
    } else {
      this.snackBar.open('Alguns campos obrigatórios estão faltando', 'Ok', {duration: 4000});
    }
  }

  createRequisition(): void {
    this.newDrList.forEach((elem) => {
      this.drService.insert(elem).subscribe( (response) => {
        this.snackBar.open(`DR criada com sucesso [${response.idDRidentifier}]`, 'Ok', {duration: 4000});
        this.uploaded = false;
      });
    });
  }

  checkSite(): void {
    this.showSiteInfo = false;
    if ( this.dr.site === '' ) {
      this.site.color = '';
      return;
    }

    this.siteService.getSiteFromCode(this.dr.site).subscribe(
      (data) => {
        if ( !data ) {
          this.site.color = 'grey';
          return;
        }

        this.siteSelected = data;
        this.siteSelected.operation = [];
        if (this.siteSelected.form_6_1 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Grua');
        }
        if (this.siteSelected.form_6_2 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Correia');
        }
        if (this.siteSelected.form_6_3 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Veiculo Esp.');
        }
        if (this.siteSelected.form_6_4 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Manual');
        }
        if (this.siteSelected.form_6_5 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Guindaste');
        }

        if (this.siteSelected.operation.length > 0) {
          this.dr.thirdPartInvoice = true;
         // console.log(this.dr.thirdPartInvoice);
        }

        if ( !data.aprovado ) {
          this.site.color = 'yellow-1';
        }
        if ( data.acesso !== ACESSO.LIBERADO ) {
          this.site.color = 'yellow-2';
        }
        if ( !data.aprovado && data.acesso !== ACESSO.LIBERADO ) {
          this.site.color = 'yellow-3';
        }

        if ( data.aprovado && data.acesso === ACESSO.LIBERADO ) {
          this.showSiteInfo = true;
          this.site.color = 'green';
        }
      },
      (error) => {
        this.site.color = 'grey';
      }
    );

  }

  setSite(idZVSD1: number): void {
    // nao modifica o site se já houver um valor
    if ( this.dr.site !== '') { return; }

    this.siteService.getSiteID(idZVSD1).subscribe(
      (data) => {
        this.dr.site = data.code.slice(-4); // ultimos 4 caracteres
        this.checkSite();
      },
      (error) => {
        this.site.color = 'grey';
      }
    );
  }

  searchOV(): void {
    // reseta itens selecionados
    this.selection.clear();

    // console.log('searchOV',this.inputOVs);
    if (this.inputOVs === undefined) {
      this.snackBar.open('Preencha um valor de OV', 'Ok', {duration: 4000});
    } else {
      this.ovService.getAll(this.inputOVs).subscribe( (data) => {
        if (!data.length) {
          this.snackBar.open('OV fora do sistema', '', {duration: 4000});
        } else {
          this.dr.prolog = data[0]['assignmentInformation'];
          this.dataSource = new MatTableDataSource<any>(data);
        }
      }, (error) => {
        this.snackBar.open('OV já utilizada ou fora do sistema', '', {duration: 4000});
      });
    }
  }

  searchNFe(): void {
    // reseta itens selecionados
    this.selection.clear();

    // console.log('searchNFe',this.inputNFs);
    if (this.inputNFs === undefined) {
      this.snackBar.open('Preencha um valor de NFe', 'Ok', {duration: 4000});
    } else {
      this.nfService.getAll(this.inputNFs).subscribe(
        (data) => {
          if ( !data || data.drId !== null ) {
            this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', {duration: 4000});
          } else {
            this.dataSource = new MatTableDataSource<any>([data]);
          }
        }, (error) => {
          // console.log(error);
          this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', {duration: 4000});
        });
    }
  }
}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: '../new-delivery-request/dialog-overview-example-dialog.html',
  styleUrls: ['./new-delivery-request.component.scss']
})
export class OverviewExampleDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<OverviewExampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onYesClick(): void {
    this.data.value = 1;
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.data.value = 0;
    this.dialogRef.close();
  }

}
