import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DashboardService } from '../../providers/dashboard.service';
import { CostKgChartComponent } from './cost-per-kilo-chart/cost-per-kilo-chart.component';
import { OccurrenceChartComponent } from './occurrence-chart/occurrence-chart.component';
import { PodsChartComponent } from './pods-chart/pods-chart.component';
import { MdrChartComponent } from './mdr-chart/mdr-chart.component';
import { MdrPieChartComponent } from './mdr-pie-chart/mdr-pie-chart.component';
import { DrsIndiceComponent } from './drs-indice/drs-indice.component';
import { Pods } from './pods-chart/pods';
import { Drs } from './drs-indice/drs';
import { Mdr } from './mdr-pie-chart/mdr';
import { Occurrence } from './occurrence-chart/occurrence';
import { ConstKgData } from './cost-per-kilo-chart/const-kg-data';
import { DrsChartComponent } from './drs-chart/drs-chart.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  costWeightData: any;
  @ViewChild('ckChart', {static: false}) costKgChartComponent: CostKgChartComponent;
  @ViewChild('occurrenceChart', {static: false}) occurrenceChartComponent: OccurrenceChartComponent;
  @ViewChild('podsChartComponent', {static: false}) podsChartComponent: PodsChartComponent;
  @ViewChild('mdrChartComponent', {static: false}) mdrChartComponent: MdrChartComponent;
  @ViewChild('mdrPieChartComponent', {static: false}) mdrPieChartComponent: MdrPieChartComponent;
  @ViewChild('drsIndiceComponent', {static: false}) drsIndiceComponent: DrsIndiceComponent;
  @ViewChild('drsChartComponent', {static: false}) drsChartComponent: DrsChartComponent;
  constructor(private dashboardService: DashboardService) { }

  sortByDate(data) {
    return data.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      if (a.createdAt <= b.createdAt) {
        return -1;
      }
    });
  }

  transformDate(data) {
    return data.map(day => {
      const currentDay = new Date(day.createdAt);
      return { 'day': currentDay.getDay() + '/' + currentDay.getMonth() + '/' + currentDay.getFullYear(), 'cost': day.cost, 'weight': day.weight };
    });
  }

  ngOnInit() {
    this.dashboardService.getMdrByShippingCompany().subscribe((data: Mdr[]) => {
      this.mdrPieChartComponent.createChart(data);
    });
    this.dashboardService.getMdrByStatus().subscribe((data: Mdr[]) => {
      this.mdrChartComponent.createChart(data);
    });
    this.dashboardService.getDrByDriverPosition().subscribe((data: Drs[]) => {
      this.drsIndiceComponent.createIndice(data);
    });
    this.dashboardService.getDrByStatus().subscribe((data: Drs[]) => {
      this.drsChartComponent.createChart(data);
    });
    this.dashboardService.getPods().subscribe((data: Pods[]) => {
      data = this.sortByDate(data);
      data = data.map(day => {
        const currentDay = new Date(day.createdAt);
        return { 'createdAt': currentDay.getDay() + '/' + currentDay.getMonth() + '/' + currentDay.getFullYear(), count: day.count };
      });
      const podsData = [];
      data.forEach(day => {
        if (podsData.length > 0) {
          if (!podsData.map(dataDay => dataDay.createdAt).includes(day.createdAt)) {
            const count = Math.ceil(data.filter(dataDay => day.createdAt === dataDay.createdAt).map(dataDay => dataDay.count).reduce((a, b) => a + b));
            podsData.push({ createdAt: day.createdAt, count: count });
          }
        } else if (podsData.length === 0) {
          const count = Math.ceil(data.filter(dataDay => day.createdAt === dataDay.createdAt).map(dataDay => dataDay.count).reduce((a, b) => a + b));
          podsData.push({ createdAt: day.createdAt, count: count });

        }
      });
      this.podsChartComponent.createChart(podsData);
    });
    this.dashboardService.getOccurrence().subscribe((data: Occurrence[]) => {
      this.occurrenceChartComponent.createChart(data);
    });
    this.dashboardService.getConstKg().subscribe((data: ConstKgData[]) => {
      data = this.sortByDate(data);
      const days = this.transformDate(data);
      const daysDataFull = [];
      days.forEach(day => {
        if (daysDataFull.length > 0) {
          if (!daysDataFull.map(dataDay => dataDay.day).includes(day.day)) {
            const cost = Math.round(days.filter(dataDay => day.day === dataDay.day).map(dataDay => dataDay.cost).reduce((a, b) => a + b) * 100) / 100;
            const weight = Math.round(days.filter(dataDay => day.day === dataDay.day).map(dataDay => dataDay.weight).reduce((a, b) => a + b) * 100) / 100;
            daysDataFull.push({ 'day': day.day, 'cost': cost, 'weight': weight, 'med': Math.ceil(( cost / weight) * 100) / 100 });

          }
        } else if (daysDataFull.length === 0) {
          const cost = Math.round(days.filter(dataDay => day.day === dataDay.day).map(dataDay => dataDay.cost).reduce((a, b) => a + b) * 100) / 100;
          const weight = Math.round(days.filter(dataDay => day.day === dataDay.day).map(dataDay => dataDay.weight).reduce((a, b) => a + b) * 100) / 100;
          daysDataFull.push({ 'day': day.day, 'cost': cost, 'weight': weight, 'med': Math.round((cost / weight) * 100) / 100 });
        }
      });
      this.costWeightData = daysDataFull;
      this.costKgChartComponent.createChart(this.costWeightData);
    });
  }
}
