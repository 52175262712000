<!-- lista de botões para inserir -->
<div *ngIf="!fileUploaded">
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'INSERT/data' | translate }}</p>
  </div>

  <div class="colored-background table-background">
    <div
      class="mx-5 py-3"
      *ngFor="let type of validUploads">
      <mat-card class="rounded-corners">
        <mat-card-content class="d-flex flex-wrap">
          <div class="col-md-12">
            <input #fileInput multiple
              class="hidden"
              type='file'
              accept='text/xlsx, text/txt'
              (change)='openFile(type,$event)'
            >
            <button mat-raised-button color="accent"
              class="w-100"
              (click)="fileInput.click()"
            >{{ 'INSERT-DATA/not-uploaded/button/' + type | translate }}</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>

<!-- tabela para mostrar dados antes de inserir -->
<div *ngIf="fileUploaded">
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'INSERT-DATA/uploaded/title' | translate }}</p>
  </div>
  <app-table
    *ngIf="dataUploaded.length > 0"
    [columns]="columns[fileUploaded]"
    [data]="dataUploaded"
  ></app-table>
  <div class="ml-auto col-md-4">
    <button mat-raised-button color="accent"
      style="position: relative; margin-bottom: 100px;"
      (click)="insert()"
    >{{ 'INSERT-DATA/uploaded/button' | translate }}</button>
  </div>
</div>
