/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ovsearch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./termresults/termresults.component.ngfactory";
import * as i3 from "./termresults/termresults.component";
import * as i4 from "@angular/router";
import * as i5 from "../../providers/ov.service";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/common";
import * as i11 from "./ovsearch.component";
import * as i12 from "../../providers/report.service";
import * as i13 from "../../misc/export-xlsx/export-xlsx";
var styles_OvsearchComponent = [i0.styles];
var RenderType_OvsearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OvsearchComponent, data: {} });
export { RenderType_OvsearchComponent as RenderType_OvsearchComponent };
function View_OvsearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-termresult", [], null, null, null, i2.View_TermResultComponent_0, i2.RenderType_TermResultComponent)), i1.ɵdid(1, 638976, null, 0, i3.TermResultComponent, [i4.ActivatedRoute, i5.OVService, i4.Router], { data: [0, "data"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OvsearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Excel Export"], ["class", "export-button"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "fas fa-file-download"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvsearchComponent_1)), i1.ɵdid(4, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_OvsearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ovsearch", [], null, null, null, View_OvsearchComponent_0, RenderType_OvsearchComponent)), i1.ɵdid(1, 114688, null, 0, i11.OvsearchComponent, [i4.ActivatedRoute, i5.OVService, i12.ReportService, i13.ExcelService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OvsearchComponentNgFactory = i1.ɵccf("app-ovsearch", i11.OvsearchComponent, View_OvsearchComponent_Host_0, {}, {}, []);
export { OvsearchComponentNgFactory as OvsearchComponentNgFactory };
