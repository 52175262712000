<div class="mx-5 my-4">
  <span class="mat-h1">{{ 'MDR_OPTIMIZER/title' | translate }} </span>
</div>
<div *ngIf="dataSource.data.length > 0" class="colored-background table-background">
   <div class="mx-5">
      <mat-form-field  class="my-3 pt-1 pl-4 rounded-corners col-md-8" floatLabel="never">
         <input matInput  [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{ 'MDR_OPTIMIZER/filter' | translate }}">
      </mat-form-field>
      <table mat-table #table  [dataSource]="dataSource" matSort>
         <p>{{ 'MDR_OPTIMIZER/list' | translate }}</p>
         <ng-container matColumnDef="select">
            <th mat-header-cell  [ngClass]="'customWidthClass'" *matHeaderCellDef>
               <mat-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="selection.hasValue() && isAllSelected()"
                             [indeterminate]="selection.hasValue() && !isAllSelected()">
               </mat-checkbox>
            <th>
            <td mat-cell [ngClass]="'customWidthClass'" *matCellDef="let row">
               <mat-checkbox (click)="$event.stopPropagation()"
                             (change)="$event ? selection.toggle(row) : null"
                             [checked]="selection.isSelected(row)">
               </mat-checkbox>
            </td>
         </ng-container>

         <ng-container  matColumnDef="pickupId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MDR_OPTIMIZER/id' | translate }} <th>
            <td mat-cell *matCellDef="let element"> {{ (element.pickupID !== null? element.pickupID: 'AW-0000' + element.id)}} </td>
         </ng-container>

         <ng-container matColumnDef="dateScheduling">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MDR_OPTIMIZER/hub' | translate }}  <th>
            <td mat-cell *matCellDef="let element"> {{element.drList[0].hub.name }} </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
   </div>
   <div class="d-flex justify-content-between m-3 pb-3">
      <button class="primary-button" mat-raised-button (click)="createNewMDRFather()">{{ 'MDR_OPTIMIZER/created' | translate }}</button>
   </div>
</div>

<div *ngIf="dataSource.data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'MDR_OPTIMIZER/empty' | translate }}</p>
</div>
