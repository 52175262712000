import {Component, OnInit, ViewChild, Inject, ElementRef, Input, SimpleChange, SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource, MatSort, MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {Occurrence} from '../../../models/occurrence';
import {environment} from '../../../../environments/environment';
import {MDR} from '../../../models/mdr';
import {UploadFileService} from '../../../providers/upload.service';
import {NfeService} from '../../../providers/nfe.service';
import {MdrService} from '../../../providers/mdr.service';
import {DomSanitizer} from '@angular/platform-browser';
import {OVService} from '../../../providers/ov.service';
// import { saveAs, encodeBase64 } from 'file-saver/FileSaver';
import {MdrObservable} from '../../../services/mdr.service';
import {UOCObservable} from '../../../services/uoc.service';
import * as JSZip from 'jszip';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})

export class AttachmentsComponent implements OnInit {
  @Input() mdr: MDR;

  @Output() updateValue = new EventEmitter<string>();

  _mdr: MDR;
  nfList: any[];
  nfFiles = [];
  rgAux = '';
  nameAux = '';
  dateAux = '';
  hourAux = '';
  podFiles = 0;
  uocList = [];
  uocFiles = [];
  sendStatus = false;
  statusContainer = false;
  totalNfCost: number;
  role = 0;

  // se existe uma NF que foi emitida a mais de 30 dias
  existeNfVermelha = false;
  today = new Date();

  updateLock = false;

  constructor(public ovService: OVService, public uploadService: UploadFileService,
              private sanitizer: DomSanitizer, public mdrService: MdrService, public snackBar: MatSnackBar,
              public nfeservice: NfeService, public uocObservable: UOCObservable, public mdrObservable: MdrObservable) {
                this.nfList = [];
                this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
              }
  ngOnInit() {
    // ao trocar a MDR
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      this.updateNF();
    });
    // ao trocarem os UOCs
    this.uocObservable.currentUOC.subscribe((data) => {
      this.uocList = data.filter(e => e.needsAttachment);
      this.updateUOCFiles();
    });
  }

  receiveFile(event) {
    this.updateNF();
    this.updateUOCFiles();
  }

  updateInfo() {
    this.nfList.forEach( (nf) => {
      if (typeof nf['file'] !== 'undefined' && nf['protected'] === false) {
        nf['file'].forEach( (file) => {
          this.uploadService.updateFileInfo(file).subscribe((data) => {
            // console.log(data);
          });
        }, this);
      }
    }, this);
  }

  removeFile(file: any, entity: string) {
    this.uploadService.deleteByEntity(file['id'], entity).subscribe(response => {
      this.updateNF();
      this.updateUOCFiles();
    });
  }

  savePOD(nf: any) {
    const info = { 'info_id' : nf.id };
    ['info_deliveredAt', 'info_name', 'info_rg', 'info_timeDeliveredAt'].forEach( (el, i, arr) => {
      if ( el in nf ) {
        info[el] = nf[el];
      }
    });
    this.uploadService.updateFileInfo( info ).subscribe( (response) => {
      this.snackBar.open('Dados do Recebedor atualizados', 'Fechar', {
        duration: 4000,
      });
    });
  }

  howManyPOD() {
    for (let i = 0; i < this.uocList.length; i++) {
      if (this.uocList[i].files.length === 0) {
        return false;
      }
    }

    for (let i = 0; i < this.nfList.length; i++) {
      if (this.nfList[i].dr === this.nfList[i].drSelected) {
        if (this.nfList[i].file === undefined) {
          return false;
        }
      }
    }

    return true;
  }

  public updateNF() {
    if (!this.updateLock) {
      this.updateLock = true;

      this.podFiles = 0;
      this.totalNfCost = 0;
      this.nfFiles = [];
      if (this.mdr.id !== null) {
        this.ovService.getNfByMdr(this.mdr.id).subscribe((data) => {
          this.nfList = data;
          let flag = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i].dr === data[i].drSelected) {
              flag = 1;
              break;
            }
          }
          if (!flag) {
            this.nfList.push({nf: 'CTE' + this.mdr.id, ov: 'HUB', dr: 1, drSelected: 1});
          }
          // console.log(this.nfList);
          setTimeout(() => {
            this.updateLock = false;
          }, this.nfList.length * 200);

          // busca NFs
          this.uploadService.findByEntity(this.mdr.id, 'mdr').subscribe((files) => {
            this.nfFiles = [].concat(this.nfFiles, files);
          });

          // anexos das NFes
          this.nfList.forEach((k) => {
            const id = +k.nf.split('-', 1)[0];

            // checa se existe NF emitida a mais de 30 dias
            k.date = new Date(k.date);
            if ( (30 * 24 * 60 * 60 * 1000) < (this.today.getTime() - k.date.getTime()) ) {
              this.existeNfVermelha = true;
            }

            if ('attachment' in k) {
              k.attachment['protected'] = true;
              this.nfFiles.push({
                url: k.attachment.path,
                fileName: k.nf + ' - ' + k.attachment.fileOriginalName,
                protected: true
              });
            }
            if (!isNaN(id)) {
              this.nfeservice.getAll_xml(id).subscribe((arr) => {

                if (arr.length > 0) {
                  k.date = new Date(arr[0].emissionDate);
                  this.uploadService.findByEntity(id, 'nfe_xml').subscribe((files) => {
                    files[0]['protected'] = true;
                    this.nfFiles.push(files[0]);
                  });
                  this.totalNfCost += +arr[0].totalValue;
                  this.updateValue.next('');
                }
              });
            }
            this.uploadService.findByEntityWithInfo(k.nf, 'nf').subscribe((ans) => {
              if (ans.length === 0) {
                k.file = undefined;
              } else {
                k.file = [];
                this.podFiles++;
                for (const i of Object.keys(ans)) {
                  k.file.push(ans[i]);
                }
              }
            });

          });

        });
      } else {
        this.updateLock = false;
      }
    }
  }

  updateUOCFiles() {
    // atualiza anexos dos UOCs
    this.uocList.forEach( (uoc, i, arr) => {
      // busca os arquivos associados
      this.mdrService.getUOCWithInfo(uoc.id).subscribe( (response) => {
        uoc['files'] = response;
      });
    });
  }

  copyData(file) {
    this.rgAux = file.info_rg;
    this.nameAux = file.info_name;
    this.dateAux = file.info_deliveredAt;
    this.hourAux = file.info_timeDeliveredAt;
  }

  pasteData(file) {
    file.info_rg = this.rgAux;
    file.info_name = this.nameAux;
    file.info_deliveredAt = this.dateAux;
    file.info_timeDeliveredAt = this.hourAux;
  }

  async dowloadAll() {
    // console.log(this.nfFiles);
    const zip = new JSZip();
    const img = zip.folder('images');
    const nffiles = this.nfFiles;
    const uploadService = this.uploadService;
    // for (const i of nffiles) {
    //   const image = i;
    //   const url = i.url;
    //   await uploadService.getImage(url).subscribe((result) => {
    //     const reader = new FileReader();
    //     // console.log(result);
    //     reader.readAsText(result);
    //     reader.onloadend = function () {
    //       const imgData = reader.result;
    //       img.file(image['id'] + image['fileName'], result, {base64: true});
    //       // console.log(i);
    //       if (i === nffiles[nffiles.length - 1]) {
    //         zip.generateAsync({type: 'blob'})
    //            .then(function (content) {
    //              // see FileSaver.js
    //              saveAs(content, 'notas_fiscais.zip');
    //            });
    //       }
    //     };
    //   });
    // }

  }

}
