import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import * as Highcharts from 'highcharts';
import * as Exporting from 'highcharts/modules/exporting';
import { ChartModule, Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cost-per-kilo-chart',
    templateUrl: './cost-per-kilo-chart.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class CostKgChartComponent implements OnInit {
    chart: Chart;
    noData = false;
    translations = {
        title: '',
        seriesName: []
    };
    constructor(public translate: TranslateService) {
        this.translations.title = this.translate.instant('COST_PER_KG');
        this.translations.seriesName.push(this.translate.instant('COST_KG'));
        this.translations.seriesName.push(this.translate.instant('COST'));
        this.translations.seriesName.push(this.translate.instant('WEIGTH'));
    }


    ngOnInit() {
    }

    createChart(data: any) {
        if (data.length > 0) {
            this.noData = false;
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: data.map(day => day.day),
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.translations.seriesName[0],
                    type: undefined,
                    data: data.map(day => day.med)
                }, {
                    name: this.translations.seriesName[1],
                    visible: false,
                    type: 'line',
                    data: data.map(day => day.cost)
                }, {
                    name: this.translations.seriesName[2],
                    visible: false,
                    type: 'line',
                    data: data.map(day => day.weight)
                }]
            });
        } else {
            this.noData = true;
        }
    }

}
