import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
import { MaterialCssVarsService } from 'angular-material-css-vars';
const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private configurations: any[];
  private token: string;
  private header;
  private url: string;
  public photo = '../../assets/images/aware-logo.png';
  constructor(
    private http: HttpClient,
    private materialCssVarsService: MaterialCssVarsService
  ) {
    this.url = base_url;
  }
  public setTheme() {

    if (localStorage.getItem('currentUser') !== null) {
      this.getConfiguration('photo').subscribe(data => {
        this.photo = data.file;
      });
      this.getConfiguration('theme').subscribe(data => {
        if (data !== undefined) {
          this.materialCssVarsService.setPrimaryColor(data.color);
        }
      });

    } else {
      this.materialCssVarsService.setPrimaryColor('#24049c'); // Aware logo color
    }
    this.materialCssVarsService.setAccentColor('#4caf50');
    this.materialCssVarsService.setContrastColorThresholdAccent('400');
  }

  private loadConfigurations(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'configuration', { headers: header }).map((configurations: any[]) => {
      this.configurations = configurations;
      return configurations;
    });
  }

  public getConfiguration(page: string, force?: boolean): Observable<any> {
    // quando as configurations já foram carregadas
    if (this.configurations && force !== true) {
      return new Observable((observer) => {
        if (this.configurations.length > 0) {
          const found = this.configurations.find(e => e.page === page);
          if (found) {
            observer.next(found.configuration);
          } else {
            throw new Error('Not found');
          }
        }
      });
    } else {
      // quando as configurations ainda não foram carregadas
      return this.loadConfigurations().pipe(
        map(
          (configurations) => {
            const found = configurations.find(e => e.page === page);
            if (found) {
              return found.configuration;
            } else {
              throw new Error('Not found');
            }
          }
        )
      );
    }
  }




  public setThemeColor(what: string) {
    this.getConfiguration(what).subscribe(data => {
      if (data !== undefined) {
        this.materialCssVarsService.setPrimaryColor(data.primary);
      } else {
        this.materialCssVarsService.setPrimaryColor('#64b5f6');
      }
    },
      (error) => {
        this.materialCssVarsService.setPrimaryColor('#64b5f6');
      });
  }

  public saveLogoImage(files: any): Observable<HttpEvent<{}>> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const formdata: FormData = new FormData();
    formdata.append('file', files._files[0]);
    return this.http.request(new HttpRequest('POST', this.url + 'configuration/upload/photo', formdata, { headers: header }));
  }

  public saveColor(color: String) {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(this.url + 'configuration', {
      'page': 'theme',
      'configuration': { 'color': color }
    }, { headers: header });
  }


}
