// import { DeliveryRequest } from "./delivery-request";
import {DR} from './dr';

export enum MDR_STATUS { MDR_EM_CRIACAO = 'MDR em criação'
                       , MDR_DEVOLVIDA_POR_LSP = 'MDR devolvida por LSP'
                       , CARGA_RETORNADA_TOTAL = 'Carga retornada total'
                       , MDR_EM_VALIDACAO = 'MDR em validação'
                       , MDR_AGUARDANDO_LSP = 'MDR aguardando LSP'
                       , MDR_APROVADA_LSP = 'MDR aprovada LSP'
                       , MOTORISTA_E_VEICULOS_DEFINIDOS = 'Motorista e veiculo definidos'
                       , VEICULO_CARREGADO = 'Veículo carregado'
                       , CARGA_ENTREGUE_COM_SUCESSO = 'Carga entregue com sucesso'
                       , ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP = 'Entrega de carga certificada pelo LSP'
                       , MDR_PAGA_CONCLUIDA = 'MDR paga / concluída'
                       , MDR_APROVADA_COM_RESSALVAS_LSP = 'MDR aprovada com ressalvas LSP'
                       , CARGA_RETORNADA_PARA_REENTREGA = 'Carga retornada para reentrega'
                       , MDR_REPROVADO = 'MDR Reprovado'
}

export enum MDR_TYPE { EDB  = 'EDB'
                     , ED = 'ED'
                     , CMS = 'CMS'
                     , DRT = 'DRT'
                     , CrossDoc = 'Cross Doc'
}


export class MDR {
    id: number;
    drList: Array<DR>;
    status: string;
    shippingCompany: string;
    vehicle: string;
    aeroDelivery: boolean;
    firstHubLeg: boolean;
    pickupDate: Date;
    pickupID: string;
    deliveryOutSOP: boolean;
    observation: string;
    totalKM: number;
    totalNF: string;
    SAD: string;
    createdAt: Date;
    createBy: string;
    frete: number;
    valorem: number;
    additional: number;
    lock: boolean;
    sapCode: string;
    type: string;

  constructor() {
        this.id = null;
        this.drList = new Array < DR >();
        this.status = '';
        this.shippingCompany = '';
        this.vehicle = '';
        this.aeroDelivery = null;
        this.firstHubLeg = null;
        this.totalKM = null;
        this.totalNF = null;
        this.pickupDate = null;
        this.pickupID = null;
        this.deliveryOutSOP = null;
        this.observation = '';
        this.SAD = '';
        this.sapCode = '';
        this.createdAt = null;
        this.createBy = '';
        this.frete = 0;
        this.valorem = 0;
        this.additional = 0;
        this.lock = true;
        this.type = null;
    }
}
