<h1 mat-dialog-title>Descreva o tratamento da Ocorrência</h1>
<div mat-dialog-content>
  <form #form="ngForm" class="row">

    <mat-form-field class="col-md-10">
      <input matInput style="width: 100%" placeholder="Comentário" name="text" [(ngModel)]="data.text" required>
    </mat-form-field>

    <!-- Checkbox is UOC -->
    <mat-checkbox class="col-md-6" name="checkboxUOC" style="display: block;" [(ngModel)]="isUOC">Essa ocorrência é uma UOC</mat-checkbox>
    <div *ngIf="isUOC">
      <mat-checkbox class="col-md-12" name="checkboxAttachment" style="display: block;" [(ngModel)]="data.needsAttachment">Essa ocorrência necessita de comprovação</mat-checkbox>
      <mat-form-field class="col-md-6">
        <mat-select placeholder="Tipo de UOC" [(ngModel)]="uocSelected" name="uocType" [required]="isUOC">
          <mat-option (click)="getUOCValue(elem)" *ngFor="let elem of uoc" [value]="elem.name">
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="Valor Base" [(ngModel)]="valueUOC" name="baseValue" [required]="isUOC">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput type="number" [step]="1" placeholder="Multiplicador" [(ngModel)]="uocMult" name="multiplier" [required]="isUOC">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="Valor Final" [(ngModel)]="totalValue" [value]="( valueUOC * uocMult )" disabled="true" name="finalValue" [required]="isUOC">
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-select placeholder="DRs" [formControl]="myControl" multiple name="DRs" [required]="isUOC">
          <mat-option *ngFor="let dr of drList" [value]="dr.id">{{dr.idDRidentifier}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Checkbox is CoPQ -->
    <div *ngIf="isUOC">
      <mat-checkbox class="col-md-6 " style="display: block;" name="checkboxCOPQ" [(ngModel)]="isCOPQ">Essa ocorrência é uma COPQ</mat-checkbox>
    </div>
    <div *ngIf="isCOPQ">
      <mat-form-field class="col-md-6">
        <mat-select placeholder="Tipo de CoPQ" [(ngModel)]="copqSelected" name="copqType" [required]="isCOPQ">
          <mat-option *ngFor="let elem of copq" [value]="elem.name">
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="Valor Base" [(ngModel)]="valueCOPQ" name="baseValueCOPQ" [required]="isCOPQ">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="Multiplicador" [step]="1" [(ngModel)]="copqMult" name="multiplierCOPQ" [required]="isCOPQ">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="Valor Final" [(ngModel)]="copqFinalValue" [value]="( valueCOPQ * copqMult )" disabled="true" name="finalValueCOPQ" [required]="isCOPQ">
      </mat-form-field>
    </div>

  </form>
</div>

<div mat-dialog-actions class="d-flex justify-content-between w-100">
  <button mat-button [disabled]="!form.valid" (click)="onYesClick()">Sim</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
