import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';

@Component({
  selector: 'app-mdr-optimizer',
  templateUrl: './mdr-optimizer.component.html',
  styleUrls: ['./mdr-optimizer.component.scss']
})

export class MdrOptimizerComponent implements OnInit {
  public searchControl: FormControl;
  inputOVs: number;
  selection = new SelectionModel<MDR>(true, []);
  opened: any;
  data: MDR[] = [];
  showLightBox = false;
  filter = '';

  displayedColumns = ['select', 'pickupId', 'dateScheduling'];
  dataSource = new MatTableDataSource<MDR>(this.data);

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader,
              private drService: DrService,
              public mdrService: MdrService, public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
    this.dataSource.sort = this.sort;
    this.mdrService.getAllWithHub().subscribe( (data) => {
     // console.log(data);
      for (let i = 0; i < data.length; i++ ) {
        if (data[i].drList[0].pickupDifAddress === true && data[i].SAD === '' &&
          (data[i].status === 'MDR em validação' || data[i].status === 'MDR em criação' )) {
          this.data.push(data[i]);
        }
      }
      this.dataSource = new MatTableDataSource<MDR>(this.data);
    });
   }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // console.log(this.dataSource.filteredData);
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => {
          if (this.dataSource.filteredData.indexOf(row) > -1) {
            this.selection.select(row);
          }
        });
  }

  createNewMDRFather() {
    const mdr = new MDR();
    mdr.status = 'MDR em validação';
    mdr.pickupID = 'BB-00';
    mdr.firstHubLeg = true;
    mdr.drList = [];
    let hubId = null;
    const drFake = new DR();
    drFake.idDRidentifier = 'BB-0001';
    const listMdrs = this.selection.selected;
    for (let i = 0; i < listMdrs.length ; i++) {
      if ( i === 0 ) {
        hubId = listMdrs[i].drList[0].pickupAddress;
      } else {
       // console.log(hubId);
       // console.log(listMdrs[i].drList[0].pickupAddress);
        if (hubId !== listMdrs[i].drList[0].pickupAddress) {
          this.snackBar.open('Não é possível faz consolidação de DRs de Hubs distintos', 'Fechar', {
            duration: 2000,
          });
          return;
        }
      }
    }

    drFake.deliveryAddress = hubId;
    drFake.pickupDifAddress = true;
    drFake.status = 'READY_TO_PICKUP';

    let value = 0;
    let weight = 0;
    let volume = 0;
    let maxWidth = 0;
    let maxLength = 0;
    let maxHeight = 0;

    for (let i = 0; i < listMdrs.length ; i++) {
      for (let j = 0; j < listMdrs[i].drList.length; j++) {
        value += parseFloat(listMdrs[i].drList[j].value + '');
        volume += parseFloat(listMdrs[i].drList[j].volume + '');
        weight += parseFloat(listMdrs[i].drList[j].weight + '');
        if ( listMdrs[i].drList[j].maxWidth > maxWidth ) {
          maxWidth = listMdrs[i].drList[j].maxWidth;
        }
        if ( listMdrs[i].drList[j].maxLength > maxLength ) {
          maxLength = listMdrs[i].drList[j].maxLength;
        }
        if ( listMdrs[i].drList[j].maxHeight > maxHeight ) {
          maxHeight = listMdrs[i].drList[j].maxHeight;
        }
      }
    }
    drFake.maxWidth = maxWidth;
    drFake.maxLength = maxLength;
    drFake.maxHeight = maxHeight;
    drFake.volume = volume;
    drFake.weight = weight;
    drFake.value = value;

    this.drService.insertFake(drFake).subscribe((el) => {
     // console.log(el);
      mdr.drList.push(el);
      this.mdrService.insert(mdr, false).subscribe( (data) => {
       // console.log('MDR new Service');
       // console.log(data);
        this.selection.selected.forEach( (mdrAux: MDR) => {
          mdrAux.SAD = data.id + '';
          this.mdrService.update(mdrAux, '1').subscribe( (response) => {

          });
        });
      });
    });


  }
}
