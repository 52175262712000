<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'FINANCE-LIST/title' | translate }}</p>
  <p class="mx-4">{{ 'FINANCE-LIST/sub-title' | translate }}</p>
  <div fxLayoutGap="30px" *ngIf="role===1">
    <!-- liberar pagamento -->
    <button mat-raised-button class="button" color="accent" (click)="payNF()" [disabled]="!listPay.length">{{ 'FINANCE-LIST/button/pay-nf' | translate }}</button>
    <!-- inserir linha -->
    <input class="hidden" type='file' #fileInputInsert accept='xlsx' (change)='insertPaymentFromFile($event)' multiple>
    <button mat-raised-button class="button" color="accent" (click)="fileInputInsert.click()">{{ 'FINANCE-LIST/button/insert' | translate }}</button>
    <!-- atualizar com xlsx -->
    <input class="hidden" type='file' #fileInputUpdate accept='xlsx' (change)='updateFromFile($event)'>
    <button mat-raised-button class="button" color="accent" (click)="fileInputUpdate.click()">{{ 'FINANCE-LIST/button/update' | translate }}</button>
    <!-- remover pagamentos -->
    <button mat-raised-button class="button" color="accent" (click)="remove()" [disabled]="!listPay.length">{{ 'FINANCE-LIST/button/remove' | translate }}</button>
  </div>
</div>
<div *ngIf="data.length > 0" class="colored-background table-background px-5 py-2">
  <app-table
    [data]="data"
    [columns]="columns"
    downloadAs="finance-list_%.xlsx"
    [dateSearch]="['createdAt']"
    showCheckbox="true"
    (check)="addList($event)"
    [this]="this"
    [clickCallback]="goToMDR"
  ></app-table>
</div>

<div *ngIf="data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'FINANCE-LIST/available' | translate }} </p>
</div>
