import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {environment} from '../../environments/environment';
import {NF} from '../models/nfe';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class ClientUserService {
  public token: string;
  private uid = 'ClientUserService-';

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getAll(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // let params = new HttpParams().set("token",token);
      return this.http.get(base_url + 'client/getAll', {headers: header})
          .map((response: any[]) => {
            // console.log(response);
            const OVdata = response;
            return OVdata;
          });
    }

  getClients(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    if ( (this.uid + 'getClients') in localStorage ) {
      return new Observable((observer) => {
        observer.next(JSON.parse(localStorage.getItem(this.uid + 'getClients')));
      });
    } else {
      return this.http.get(base_url + 'client/GetClients', {headers: header})
                 .map((response: any[]) => {
                   localStorage.setItem(this.uid + 'getClients', JSON.stringify(response));
                   return response;
                 });
    }
  }

  deleteUser(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('id', id + '');
    return this.http.delete(base_url + 'client/user/' + id, {headers: header, params: params})
               .map((response: any) => {
                 return response;
               });
  }

  remove(id: number): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('id', id + '');
    return this.http.delete(base_url + 'client/' + id, {headers: header, params: params})
      .map((response: any[]) => {
        // console.log(response);
        const OVdata = response;
        return OVdata;
      });
  }

  getTpUsers(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'client/GetTps', {headers: header})
      .map((response: any[]) => {
        // console.log(response);
        const OVdata = response;
        return OVdata;
      });
  }

  create(clientId: string, userId: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'client/', {clientId: clientId, userId: userId}, {headers: header})
      .map((response) => {
        // console.log(response);
        const OVdata = response;
        return OVdata;
      });
  }

  createUser(userName: string, userEmail: string, companyId: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'client/CreateUser', {userName: userName, userEmail: userEmail, companyId: companyId}, {headers: header})
      .map((response) => {
        const res = response;
       // console.log(res);
        return res;
      });
  }

  getUserFromLSP(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'client/getAllUsers', {headers: header})
      .map((response: any[]) => {
        // console.log(response);
        const OVdata = response;
        return OVdata;
      });
  }

  getLspList(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'client/GetLsp', {headers: header})
      .map((response: any[]) => {
        // console.log(response);
        const OVdata = response;
        return OVdata;
      });
  }
}
