<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'ATTACHMENTS/title' | translate }}
        </div>
      </div>
      <hr>

      <div class="row border-button">
        <div class="col-md-11">
          <span class="title">{{ 'ATTACHMENTS/nf' |translate }}</span>
          <button *ngIf="nfFiles.length" class="button green-button text-white" style="float: right; width: 150px; height: 38px;" (click)="dowloadAll()"> {{ 'ATTACHMENTS/download' | translate }} </button>
        </div>
        <div *ngIf="role !== 10" class="col-md-1">/
          <app-upload-files [entity]="'mdr'" [id]="mdr.id" (messageEvent)="receiveFile($event)" > </app-upload-files>
        </div>
      </div>
      <div *ngIf="nfList !== undefined">
        <div *ngFor="let nf of nfFiles" class="row">
          <div class="column col-md-11" >
            <a [href]="nf.url" target="_blank"> {{ nf.fileName }} </a>
          </div>
          <div class="column col-md-1" *ngIf="!nf.protected">
            <button class="button red-button text-white"  mat-raised-button   (click)="removeFile(nf, 'mdr')"> {{ 'ATTACHMENTS/remove' | translate }} </button>
          </div>
        </div>
      </div>

      <!-- fim NFs -->

      <div class="row border-button">
        <div class="col-md-9">
          <span class="title">{{ 'ATTACHMENTS/pod' | translate }}</span>
        </div>
      </div>
      <div *ngFor="let nf of nfList">
        <div *ngIf='nf.dr === nf.drSelected' class="border-button">
          <div  class="row title-anex">
            <div class="col-md-11 center-text">
              <i *ngIf="( (today.getTime() - nf.date.getTime()) <= (15*24*60*60*1000))"
                class="fas fa-check-circle"
                style="color: greenyellow;"
                matTooltip="Nota Fiscal emitida menos de 15 dias atrás"
              ></i> <!-- NF emitida a menos de 15 dias -->
              <i *ngIf="( (15*24*60*60*1000) < (today.getTime() - nf.date.getTime()) && (today.getTime() - nf.date.getTime()) <= (30*24*60*60*1000) )"
                class="fas fa-exclamation-triangle"
                style="color: yellow;"
                matTooltip="Nota Fiscal emitida entre 15 e 30 dias atrás"
              ></i> <!-- NF emitida a entre 15 e 30 dias -->
              <i *ngIf="( (30*24*60*60*1000) < (today.getTime() - nf.date.getTime()) )"
                class="fas fa-times-circle"
                style="color: red;"
                matTooltip="Nota Fiscal emitida mais de 30 dias atrás"
              ></i> <!-- NF emitida a mais de 30 dias -->
              {{nf.ov}} - {{nf.nf}}
            </div>
            <div *ngIf="role !== 10" class="col-md-1 right-text">
              <app-upload-files [id]="nf.nf" [entity]="'nf'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 my-3" *ngFor="let file of nf.file"><!-- para cada POD ja subido -->
              <div class="d-flex">
                <app-image-lightbox class="col-md-4"  [images]="[file]"></app-image-lightbox>
                <form class="col-md-7"> 
                  <div align="right">
                     <i class="fa fa-copy" style="margin-right: 10px;" matTooltip="{{ 'ATTACHMENTS/copy' | translate }}" (click)="copyData(file)"></i>
                     <i class="fa fa-paste"  matTooltip="{{ 'ATTACHMENTS/paste' | translate }}" (click)="pasteData(file)"></i>
                  </div>
                  <mat-form-field class="col-md-12"><input matInput placeholder="{{ 'ATTACHMENTS/name' | translate }}" name="info_name" [(ngModel)]="file.info_name"></mat-form-field>
                  <mat-form-field class="col-md-4"><input matInput placeholder="{{ 'ATTACHMENTS/rg' | translate }}" name="info_rg" [(ngModel)]="file.info_rg"></mat-form-field>
                  <mat-form-field class="col-md-4">
                    <input matInput
                      name="deliveredat"
                      [(ngModel)]="file.info_deliveredAt"
                      placeholder="Data da entrega"
                      [owlDateTimeTrigger]="myDatepickerCal"
                      [owlDateTime]="myDatepickerCal">
                    <owl-date-time #myDatepickerCal pickerType="calendar"></owl-date-time>
                  </mat-form-field>
                  <mat-form-field class="col-md-3">
                    <input matInput
                      [owlDateTimeTrigger]="myTimepicker"
                      [owlDateTime]="myTimepicker"
                      placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
                      [(ngModel)]="file.info_timeDeliveredAt"
                      name="timedeliveredat">
                    <owl-date-time #myTimepicker pickerType="timer"></owl-date-time>
                  </mat-form-field>
                  <div class="col-md-12">{{ 'ATTACHMENTS/date' | translate }} {{ file.createdAt | date :'dd/MM/yyyy hh:mm:ss'}}</div>
                  <div class="d-flex justify-content-between">
                    <button class="button green-button text-white col-md-4" mat-raised-button (click)="savePOD(file)"> {{ 'ATTACHMENTS/update' | translate }}  </button>
                    <button class="button red-button text-white col-md-4" mat-raised-button (click)="removeFile(file, 'nf')"> {{ 'ATTACHMENTS/remove' | translate }} </button>
                  </div>
                </form>
              </div>
            </div>
          </div><!-- fim POD -->
          </div>
        </div>

      <div class="row border-button">
        <div class="col-md-9">
          <span class="title">{{ 'ATTACHMENTS/uoc' | translate }}</span>
        </div>
        <div *ngIf="uocList !== undefined" class="col-md-12">
          <div *ngFor="let uoc of uocList" class="row">
            <div class="col-md-11" >
              {{ uoc.uoc }}
            </div>
            <div *ngIf="role !== 10" class="col-md-1"  >
              <app-upload-files [id]="uoc.id" [entity]="'uoc'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
            </div>
            <div *ngFor="let file of uoc.files" class="col-md-12 row">
              <app-image-lightbox class="col-md-6" [images]="[file]"></app-image-lightbox>
              <!-- <div class="column col-md-10" >
                <a [href]="file.url" target="_blank"> {{ file.fileName }} </a>
                 <button class="button red-button text-white remove"  mat-raised-button (click)="removeFile(file, 'uoc')"> {{ 'ATTACHMENTS/remove' | translate }} </button>
              </div> -->
              <div align="left" class="col-md-1"> 
                <i class="fa fa-times" (click)="removeFile(file, 'uoc')"></i>
              </div>
            </div>
          </div>
        </div>
      </div><!-- fim UOC -->
    </div>
  </div>
</div>
