var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { MatSort, MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
import { MDR, MDR_TYPE, MDR_STATUS } from '../../models/mdr';
import { MdrObservable } from '../../services/mdr.service';
import { PriceObservable } from '../../services/price.service';
import { DrObservable } from '../../services/dr.service';
import { UOCObservable } from '../../services/uoc.service';
import { DrService } from '../../providers/dr.service';
import { DatePipe } from '@angular/common';
import { UOCMDR } from '../../models/uoc__mdr';
import { Driver } from '../../models/driver';
import 'rxjs/add/operator/takeWhile';
import { TranslateService } from '@ngx-translate/core';
import { TransportComponent } from './transport/transport.component';
import { Message } from '../../models/message';
import { SocketService } from '../../providers/socket.service';
import { ChatService } from '../../providers/chat.service';
import { Event } from '../../models/events';
// import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { Title } from '@angular/platform-browser';
// import * as FileSaver from 'file-saver';
export class LogisticOrderComponent {
    constructor(dialog, titleService, mdrService, router, aRoute, snackBar, mdrObservable, priceObservable, drObservable, drService, uocObservable, socketService, chat) {
        this.dialog = dialog;
        this.titleService = titleService;
        this.mdrService = mdrService;
        this.router = router;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.mdrObservable = mdrObservable;
        this.priceObservable = priceObservable;
        this.drObservable = drObservable;
        this.drService = drService;
        this.uocObservable = uocObservable;
        this.socketService = socketService;
        this.chat = chat;
        this.chatType = 5; // transportadora <-> motorista
        this.drivers = [];
        this.print = false;
        this.mdr = new MDR();
        this.editable = true;
        this.driverEditable = false;
        this.role = 0;
        this.editMDRStatus = false;
        this.editMDRType = false;
        // use *ngIf="display" in your html to take
        // advantage of this
        this.reservation = '';
        this.reprove = '';
        this.shipmentReturned = '';
        this.positionOptions = 'after';
        this.tooltip = 'Esta MDR esta sendo editada por outra pessoa';
        this.price = { price: 0, total: 0, adValorem: 0, addDelivery: 0, taxedPrice: 0, sc: '', vehi: '' };
        this.hiddenMap = true;
        this.lock = false;
        this.valueNfStatus = false;
        this.mdrStatus = [
            { value: 'MDR em validação', name: 'MDR em validação' },
            { value: 'MDR em criação', name: 'MDR em criação' },
            { value: 'MDR devolvida por LSP', name: 'MDR devolvida por LSP' },
            { value: 'MDR aguardando LSP', name: 'MDR aguardando LSP' },
            { value: 'MDR aprovada LSP', name: 'MDR aprovada LSP' },
            { value: 'Motorista e veiculo definidos', name: 'Motorista e veiculo definidos' },
            { value: 'Veículo carregado', name: 'Veículo carregado' },
            { value: 'Carga entregue com sucesso', name: 'Carga entregue com sucesso' },
            { value: 'Carga retornada total', name: 'Carga retornada total' },
            { value: 'Entrega de carga certificada pelo LSP', name: 'Entrega de carga certificada pelo LSP' },
            { value: 'MDR paga / concluída', name: 'MDR paga / concluída' },
        ];
        this.mdrType = [
            { value: MDR_TYPE.EDB, name: 'EDB' },
            { value: MDR_TYPE.ED, name: 'ED' },
            { value: MDR_TYPE.CMS, name: 'CMS' },
            { value: MDR_TYPE.DRT, name: 'DRT' },
            { value: MDR_TYPE.CrossDoc, name: 'Cross Doc' },
        ];
        this.driver = new Driver();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.display = false;
        this.alive = true;
    }
    ngOnDestroy() {
        this.titleService.setTitle('Aware');
    }
    ngOnInit() {
        this.aRoute.params.subscribe((params) => {
            this.channel = params.id;
            // busca MDR
            this.mdrService.getMDR(params.id).subscribe((data) => {
                this.mdr = data;
                //        console.log(this.mdr);
                // busca NFe
                this.mdrService.getNf(params.id).subscribe((nfs) => {
                    const totalValue = nfs.filter(nf => parseFloat(nf.totalValue) >= 500000);
                    if (totalValue.length > 0) {
                        this.valueNfStatus = true;
                    }
                });
                // setta o titulo da página
                if (this.mdr.pickupID !== null) {
                    this.titleService.setTitle(this.mdr.pickupID);
                }
                // de acordo com mdr.status
                switch (this.mdr.status) {
                    case 'MDR aprovada com ressalvas LSP':
                        this.mdrService.getMDRReservation(params.id).subscribe((elem) => {
                            this.reservation = elem;
                        });
                        break;
                    case 'MDR em criação':
                    case 'MDR devolvida por LSP':
                        this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
                            this.reprove = elem;
                        });
                        break;
                    case 'Carga retornada total':
                        this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
                            this.shipmentReturned = elem;
                        });
                        break;
                }
                // pega DRs associadas
                this.drService.getByMdr(params.id).subscribe((newDr) => {
                    this.drObservable.changeDR(newDr);
                });
                // pega UOCs da MDR
                this.mdrService.getUOC(this.mdr).subscribe((uoc) => {
                    this.uocObservable.changeUOC(uoc);
                });
                // atualiza Observable da MDR
                this.mdrObservable.changeMDR(this.mdr);
                // inicializa socket
                this.initIoConnection(this.channel);
                // de acordo com mdr.status
                switch (this.mdr.status) {
                    case 'MDR em validação':
                        this.mdrService.createLegs(this.mdr.id, false).subscribe((legs) => {
                            //
                        });
                        break;
                    case 'MDR em criação':
                        this.mdrService.getAllPriceR(this.mdr).subscribe((elem) => {
                            this.price = elem;
                            this.mdr.totalNF = elem.val;
                            this.mdr.sapCode = elem.sapCd;
                            this.mdr.totalKM = elem.km;
                            this.mdr.shippingCompany = elem.sc;
                            this.mdr.vehicle = elem.vehi;
                            this.priceObservable.changePrice(elem);
                            this.mdrObservable.changeMDR(this.mdr);
                        });
                        break;
                    default: // todos os outros status
                        this.mdrService.getAlreadyExistentPrice(this.mdr).subscribe((elem) => {
                            this.price = elem;
                            this.price.addDelivery = elem.addEntrega;
                            this.price.adValorem = elem.adValorem;
                            this.price.price = elem.frete;
                            this.price.total = elem.total;
                            this.price.taxedPrice = elem.freteTax;
                            this.priceObservable.changePrice(elem);
                        });
                        break;
                }
            }, (error) => {
                this.snackBar.open('Erro ao carregar MDR.', 'Ok', {
                    duration: 4000,
                });
                this.router.navigate(['/']);
            });
        });
    }
    updateNFCost() {
        this.tax.nfePrice = this.attachments.totalNfCost;
    }
    ngAfterViewInit() {
    }
    mapButtonClick() {
        this.hiddenMap = !this.hiddenMap;
    }
    showMap() {
        switch (this.mdr.status) {
            case 'MDR em validação':
            case 'MDR em criação':
            case 'MDR devolvida por LSP':
            case 'MDR aguardando LSP':
            case 'MDR aprovada LSP':
            case 'Motorista e veiculo definidos':
            case 'Veículo carregado':
                return true;
            default:
                return false;
        }
    }
    onClickPrint() {
        const mdrID = this.mdr.id;
        this.mdrService.getPDF(this.mdr.id).subscribe((data) => {
            // FileSaver.saveAs(data, `Report-Logistic_Order-${mdrID}.pdf`);
        });
    }
    editStatus() {
        this.editMDRStatus = !this.editMDRStatus;
    }
    saveStatus() {
        this.editMDRStatus = !this.editMDRStatus;
        this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe((data) => {
            this.snackBar.open('Status atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
                duration: 4000,
            });
        });
    }
    editMDRTypeAllowed(status) {
        if (status === MDR_STATUS.MDR_EM_CRIACAO) {
            return true;
        }
        return false;
    }
    editType() {
        this.editMDRType = !this.editMDRType;
    }
    saveType(new_type) {
        this.editMDRType = !this.editMDRType;
        this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe((data) => {
            this.snackBar.open('Tipo de MDR atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
                duration: 4000,
            });
        }, (error) => {
            this.snackBar.open('Não foi possivel atualizar a MDR.', 'Ok', {
                duration: 4000,
            });
        });
    }
    createDrivers() {
        this.transport.drivers.forEach((el, i, arr) => {
            this.createDriver(el);
        });
    }
    createDriver(driver) {
        driver.mdrId = this.mdr.id;
        this.mdrService.createDriver(driver).subscribe((response) => {
            // console.log(response);
        });
    }
    initIoConnection(id) {
        this.user = this.currentUser.user.id;
        this.socketService.initSocketMDR(id, this.user);
        // this.ioConnection = this.socketService.onComeMDRLists()
        //   .subscribe((message) => {
        //    //console.log(message);
        //     this.idsocket= message['user'][this.user];
        //     if(message['socket'][0]!==this.idsocket){
        //       this.lock =false;
        //     }else{
        //       this.lock =true;
        //     }
        //     this.aRoute.params.subscribe( params => {
        //       this.mdrService.getMDR(params.id).subscribe((data) => {
        //         this.mdr = data;
        //         this.mdr.lock = this.lock;
        //         this.mdrObservable.changeMDR(this.mdr);
        //       })
        //     });
        //
        //   });
        this.socketService.onEvent(Event.CONNECT)
            .subscribe(() => {
            // console.log('connected');
        });
        this.socketService.onEvent(Event.NEW_MESSAGE)
            .subscribe((message) => {
            // console.log(message);
            // this.messages.push(message);
        });
        this.socketService.onEvent(Event.DISCONNECT)
            .subscribe(() => {
            // console.log('disconnected');
        });
    }
    cancelMDR() {
        this.mdr.status = 'MDR aguardando LSP';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    deleteMDR() {
        this.mdr.status = 'MDR Reprovado';
        this.mdrService.update(this.mdr, this.user).subscribe((response) => __awaiter(this, void 0, void 0, function* () {
            yield this.mdrService.liberateDR(this.mdr).subscribe((data) => {
                this.snackBar.open(this.mdr.status, 'Ok', {
                    duration: 4000,
                });
            });
            this.router.navigate(['delivery-order']);
        }));
    }
    createToken() {
        this.mdrService.createToken(this.mdr.id).subscribe((response) => {
            const link = 'view/' + response.token + '/mdr';
            const dialogRef = this.dialog.open(CreateTokenDialogComponent, {
                data: {
                    link: link
                }
            });
        });
    }
    reproveMDR() {
        const dialogRef = this.dialog.open(AcceptMdrReproveDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response[0] === true) {
                this.mdr.shippingCompany = '';
                this.mdrService.updateReprove(this.mdr, response[1]).subscribe((data) => {
                    this.snackBar.open(this.mdr.status, 'Ok', {
                        duration: 4000,
                    });
                    this.router.navigate(['delivery-order']);
                });
            }
            else {
                this.router.navigate(['delivery-order']);
            }
        });
    }
    openHistory() {
        this.mdrService.getHistory(this.mdr.id).subscribe((history) => {
            const dialogRef = this.dialog.open(MdrHistoryDialogComponent, {
                width: '80vw',
                maxHeight: '80vh',
                data: { history: history }
            });
        });
    }
    giveBackMDR() {
        this.mdr.status = 'MDR devolvida por LSP';
        this.mdr.shippingCompany = '';
        this.mdrService.update(this.mdr, this.user).subscribe((response) => {
            this.mdrService.giveBack(this.mdr).subscribe((data) => {
            });
        });
    }
    cancelTake() {
        this.mdr.status = 'MDR em criação';
        this.mdr.shippingCompany = '';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
        });
    }
    allShipmentReturned() {
        const dialogRef = this.dialog.open(AcceptMdrReturnDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response[0] === true) {
                this.mdr.status = 'Carga retornada total';
                this.notification(response);
                this.mdrService.updateReturn(this.mdr, response[1]).subscribe((data) => {
                });
            }
            else {
                this.router.navigate(['delivery-order']);
            }
        });
    }
    updateMDR() {
        this.transport.saveDriver();
        this.transport.saveConveyor();
        this.tax.saveConveyor();
        this.tax.saveUOC();
        this.tax.saveCOPQ();
        this.tax.saveCA();
        this.attachments.updateInfo();
    }
    approveWithConditionsMDR() {
        const dialogRef = this.dialog.open(AcceptMdrReservationDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response[0] === true) {
                this.mdr.status = 'MDR aprovada com ressalvas LSP';
                this.notification(response);
                this.mdrService.updateRessalva(this.mdr, response[1]).subscribe((data) => {
                });
            }
            else {
                this.router.navigate(['delivery-order']);
            }
        });
    }
    returnTodeliveryAgain() {
        this.mdr.status = 'Carga retornada para re-entrega';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    sendLSP() {
        // verifica se as informações de transportadora estão preenchidas
        // if ( ['shippingCompany', 'vehicle', 'pickupDate', 'pickupCode'].some( e => {
        //   if (this.transportInfo.mdr[e] === null ||
        //      this.transportInfo.mdr[e] === '' ) {
        //     return true;
        //   }
        //   return false;
        // })){
        //   this.snackBar.open('Complete as informações da transportadora antes de prosseguir', 'Ok', { duration: 4000 });
        //   return;
        // }
        // verifica se a data de coleta é válida
        // const pickupDate = new Date(this.transportInfo.mdr.pickupDate);
        // this.drService.getByMdr(this.transportInfo.mdr.id).subscribe((drList) => {
        //   if( drList.some( e => {
        //     let dateScheduling = new Date(e.dateScheduling);
        //     if(pickupDate.getTime() > dateScheduling.getTime()) {
        //       // se a data de coleta for maior que a data da entrega
        //       return true;
        //     }
        //     return false;
        //   })){
        //     this.snackBar.open("Data de coleta deve ser menor que a data de entrega",'Ok', { duration: 4000 });
        //   } else {
        // data é válida, prossegue
        this.mdrService.getLSPUsers(this.mdr.shippingCompany).subscribe((users) => {
            if (users !== null) {
                if (users.length > 0) {
                    const dialogRef = this.dialog.open(LSPEmailDialogComponent, {
                        data: {
                            users: users,
                            existeNfVermelha: this.attachments.existeNfVermelha,
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result[0]) {
                            const selectedUsers = result[1].value;
                            if (selectedUsers !== null) {
                                this.mdrService.sendLSPEmail(this.mdr, selectedUsers).subscribe();
                            }
                            this.tax.savePrice();
                            if (this.mdr.type === 'CMS' && this.mdr.SAD === 'true') {
                                const uoc = new UOCMDR();
                                uoc.idMDR = this.mdr.id;
                                uoc.value = this.tax.getTotal() * 0.5 * 100;
                                uoc.uoc = 'Retorno';
                                this.mdrService.createUOC(uoc).subscribe((resp) => {
                                    // console.log(resp);
                                });
                            }
                            this.mdr.status = 'MDR aguardando LSP';
                            this.mdrService.update(this.mdr, this.user).subscribe((data) => {
                                // this.router.navigate(['delivery-optimizer']);
                            });
                        }
                    });
                }
                else {
                    if (this.mdr.type === 'CMS' && this.mdr.SAD === 'true') {
                        const uoc = new UOCMDR();
                        uoc.idMDR = this.mdr.id;
                        uoc.value = this.tax.getTotal() * 0.5 * 100;
                        uoc.uoc = 'Retorno';
                        // console.log(uoc);
                        this.mdrService.createUOC(uoc).subscribe((resp) => {
                            // console.log(resp);
                        });
                    }
                    this.tax.savePrice();
                    this.mdr.status = 'MDR aguardando LSP';
                    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
                        this.router.navigate(['delivery-optimizer']);
                    });
                }
            }
        });
        // this.mdrService.savePrice(this.price, this.mdr).subscribe((el) => {
        // console.log('chegou aqui!');
        // });
    }
    saveAddressInMapp() {
        this.mdr.status = 'MDR em criação';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
            this.router.navigate(['list-mdrs']);
        });
    }
    reproveRessalva() {
        this.mdr.status = 'MDR em criação';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    aproveRessalva() {
        this.mdr.status = 'MDR aprovada LSP';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    acceptMDR() {
        this.mdr.status = 'MDR aprovada LSP';
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    defineDriver() {
        if (this.transport.drivers.length === 0) {
            this.snackBar.open('Favor definir um motorista', 'Ok', { duration: 4000 });
            return;
        }
        this.mdr.status = 'Motorista e veiculo definidos';
        this.createDrivers();
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            // this.notification(data);
        });
    }
    vehicleLoaded() {
        this.mdr.status = 'Veículo carregado';
        // //console.log(this.mdr);
        this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
            for (let i = 0; i < drList.length; i++) {
                if (drList[i].status === 'READY_TO_PICKUP') {
                    drList[i].status = 'InTransit';
                    this.drService.update(drList[i]).subscribe(k => {
                        // console.log("UPDATED");
                    });
                }
            }
        });
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    delivered() {
        this.mdr.status = 'Carga entregue com sucesso';
        this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
            for (let i = 0; i < drList.length; i++) {
                if (drList[i].status === 'READY_TO_PICKUP' || drList[i].status === 'InTransit') {
                    drList[i].status = 'Delivered';
                    this.drService.update(drList[i]).subscribe(k => {
                        // console.log("UPDATED");
                    });
                }
            }
        });
        this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.notification(data);
        });
    }
    deliveredCertified() {
        if (this.attachments.howManyPOD()) {
            const dialogRef = this.dialog.open(DeliveredCertifiedDialogComponent, {
                width: '600px',
                data: {
                    text: '',
                }
            });
            dialogRef.afterClosed().subscribe((response) => {
                if (response[0] === true) {
                    this.mdr.status = 'Entrega de carga certificada pelo LSP';
                    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
                        this.router.navigate(['delivery-order']);
                    });
                }
            });
        }
        else {
            this.snackBar.open('Faltam inserir PODs ou Foto de UOC', 'Ok', {
                duration: 4000,
            });
        }
    }
    pay() {
        this.mdr.status = 'MDR paga / concluída';
        this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
            let k = true;
            drList.forEach((dr) => {
                if (!dr.prolog) {
                    k = false;
                }
            });
            // if (k) {
            this.mdrService.update(this.mdr, this.user).subscribe((data) => {
                this.notification(data);
            });
            // } else {
            //   this.snackBar.open('DRs sem prolog. Impossível realizar', 'Ok', {
            //     duration: 4000,
            //   });
            // }
        });
    }
    goToChat(chatType) {
        this.router.navigate(['chat/' + this.mdr.id + '/' + chatType]);
    }
    notification(data) {
        this.socketService.initSocketNotification(Number(this.user));
        const message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
        this.socketService.sendNotification(message);
        // console.log(data);
        data.forEach(value => {
            const notification = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), this.chatType);
            this.socketService.sendNotification(notification);
        });
        this.snackBar.open(this.mdr.status, 'Ok', {
            duration: 4000,
        });
        this.router.navigate(['delivery-optimizer']);
    }
}
export class AcceptMdrReservationDialogComponent {
    constructor(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    ngOnInit() {
    }
}
export class AcceptMdrReturnDialogComponent {
    constructor(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    ngOnInit() {
    }
}
export class AcceptMdrReproveDialogComponent {
    constructor(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    ngOnInit() {
    }
}
export class DeliveredCertifiedDialogComponent {
    constructor(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    ngOnInit() {
    }
}
export class LSPEmailDialogComponent {
    constructor(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        this.selected = new FormControl();
        // console.log(translate);
    }
    ngOnInit() {
    }
}
export class CreateTokenDialogComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    onClickOk() {
        this.dialogRef.close();
    }
}
export class MdrHistoryDialogComponent {
    constructor(data, datepipe) {
        this.data = data;
        this.datepipe = datepipe;
        this.history = [];
        this.columns = [
            { value: 'uid', name: 'LOGISTIC-ORDER/history/id/' },
            { value: 'status', name: 'LOGISTIC-ORDER/history/status/' },
            { value: 'quem', name: 'LOGISTIC-ORDER/history/by/' },
            { value: 'quando', name: 'LOGISTIC-ORDER/history/on/' },
            { value: 'pickupDate', name: 'LOGISTIC-ORDER/history/date/' },
            { value: 'pickupID', name: 'LOGISTIC-ORDER/history/pickupId/' },
            { value: 'observation', name: 'LOGISTIC-ORDER/history/observation/' },
            { value: 'vehicle', name: 'LOGISTIC-ORDER/history/vehicle/' },
        ];
        this.history = data.history;
        this.history.forEach(info => {
            info.quando = this.datepipe.transform(info.quando, 'dd/MM/yyyy hh:mm:ss');
            info.pickupDate = this.datepipe.transform(info.pickupDate, 'dd/MM/yyyy hh:mm:ss');
        });
    }
    ngOnInit() {
    }
}
