<div class="colored-background table-background">
    <div class="mx-5 py-3">
        <mat-card class="rounded-corners">
            <mat-card-content>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field class="center">
                          <input matInput
                            [owlDateTimeTrigger]="start"
                            [owlDateTime]="start"
                            placeholder="Inicio"
                            [min]="minDate"
                            [max]="maxDate"
                            [(ngModel)]="startDate">
                          <owl-date-time #start pickerType="calendar"></owl-date-time>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field class="center">
                          <input matInput
                            [owlDateTimeTrigger]="end"
                            [owlDateTime]="end"
                            placeholder="Fim"
                            [min]="minDate"
                            [max]="maxDate"
                            [(ngModel)]="endDate">
                          <owl-date-time #end pickerType="calendar"></owl-date-time>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field class="center">
                            <mat-label>Relatorio</mat-label>
                            <mat-select [(value)]="report">
                                <mat-option *ngFor="let report of reportList" [value]="report.type">{{report.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <button mat-flat-button (click)="download()" class="center fill-h">Download XLSX</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
