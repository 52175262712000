import { Pipe, PipeTransform } from '@angular/core';
/*
 * Formata o id da MDR recebida
 * Recebe um valor inteiro
 *
 * Uso:
 *   Number | mdr-id
 * Exempo:
 *   {{ 132 | mdr-id }}
 *   resulta em: 'BB000132'
 */
@Pipe({name: 'mdrId'})
export class MdrIdPipe implements PipeTransform {
  transform(id: number): string {
    const len = 6;
    const preffix = 'AW';
    return preffix + ('0000000' + id).slice(-len);
  }
}
