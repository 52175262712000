import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import * as Highcharts from 'highcharts';
import * as Exporting from 'highcharts/modules/exporting';
import { ChartModule, Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-occurrence-chart',
    templateUrl: './occurrence-chart.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class OccurrenceChartComponent implements OnInit {
    chart: Chart;
    noData = false;
    constructor(public translate: TranslateService) { }

    ngOnInit() {
    }

    createChart(data: any) {
        if (data.length > 0) {
            this.noData = false;
            const series = data.map(op => {
                return { name: this.translate.instant(op.occ_status), type: undefined, data: [op.count] };
            });
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                  type: 'category',
                  labels: {
                    format: 'Status'
                  }
                },
                tooltip: {
                  formatter: function() {
                    return '<b>' + this.series.name + ':</b> ' + this.y;
                  }
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: series
            });
        } else {
            this.noData = true;
        }
    }
}
