import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from './providers/auth.service';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ConfigurationService } from './providers/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aware';

  constructor(public auth: AuthService, public translate: TranslateService,
    private configurationService: ConfigurationService) {
    translate.setDefaultLang('en');
    this.configurationService.setTheme();
    const browserLang = translate.getBrowserLang();
    // console.log(browserLang);
    if (browserLang !== undefined) {
      translate.use(browserLang);
    } else {
      translate.use('pt');
    }

    (<any>window).Conpass.init();

  }
}
