import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Occurrence, OCCURRENCE_STATUS} from '../../../models/occurrence';
import {MDR} from '../../../models/mdr';
import {MdrService} from '../../../providers/mdr.service';
import {UOCMDR} from '../../../models/uoc__mdr';
import {UOC} from '../../../models/uoc';
import {FormControl} from '@angular/forms';
import {DR} from '../../../models/dr';
import {ActivatedRoute} from '@angular/router';
import {DrService} from '../../../providers/dr.service';
import {DrObservable} from '../../../services/dr.service';
import {UOCObservable} from '../../../services/uoc.service';
import {MdrObservable} from '../../../services/mdr.service';
import {Transportadora} from '../transport/transport.component';
import {DomSanitizer} from '@angular/platform-browser';
import {Copq} from '../../../models/copq';
import {COPQMDR} from '../../../models/copq__mdr';
import {UploadFileService} from '../../../providers/upload.service';
import {environment} from '../../../../environments/environment';

// table with expandable rows:
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-occurrence',
  templateUrl: './occurrence.component.html',
  styleUrls: ['./occurrence.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OccurrenceComponent implements OnInit {
  base_url = environment.apiUrl;
  dataOccurrence = new MatTableDataSource<Occurrence>();
  occurrenceColumns = ['uoc', 'text', 'status', 'createdAt', 'upload', 'file', 'resolution'];
  columnsToDisplay = ['uoc', 'text', 'status'];
  expandedElement: Occurrence | null;
  myControl = new FormControl();
  drList: DR[] = [];
  occurrence: Occurrence = new Occurrence();
  currentUser: any;
  role = 0;
  mdr: MDR = new MDR;
  uocColumns = ['uoc', 'value', 'observation'];
  uoc: UOC[] = [];
  valueUOC: number;
  sumTotal = 0;
  data: DR[] = [];
  copq: Copq[] = [];
  dataSource = new MatTableDataSource<DR>(this.data);
  constructor(public dialog: MatDialog, public mdrService: MdrService,
              public snackBar: MatSnackBar,
              public aRoute: ActivatedRoute, private drService: DrService,
              public drObservable: DrObservable, public mdrObservable: MdrObservable,
              public uploadService: UploadFileService,
      private sanitizer: DomSanitizer) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.occurrenceColumns.push('action');
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      this.drObservable.currentDR.subscribe(dr => {
        this.drList = dr;
      });
      this.getListOccurrence();
    });
    this.mdrService.getListCoPQ().subscribe( copq => {
      this.copq = copq;
    });
    this.mdrService.getOccurrenceEnum().subscribe((data) => {
      this.uoc = data.map((e) => {
        const u = new UOC();
        u.id = e.id;
        u.name = e.occurrence;
        return u;
      });
    });
  }
  ngOnInit() {
  }
  createOccurrence() {
    this.occurrence.idMDR = this.mdr.id;
    // console.log(this.occurrence);
    this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
    if (this.occurrence.uoc === 'Status') { this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED; } // gambiarra, remover, corrigir TODO DEBUG
    this.mdrService.createOccurrence(this.occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  getListOccurrence() {
    this.mdrService.getOccurences(this.mdr).subscribe((data: Occurrence[]) => {
      if (this.role === 1) {
        data = data.filter(occurrence => occurrence.status !== OCCURRENCE_STATUS.SENT_TO_LSP)
                   .filter(occurrence => occurrence.status !== OCCURRENCE_STATUS.OCCURRENCE_DENIED);
      }
      for (let j = 0; j < data.length; j++) {
        this.uploadService.findByEntity(data[j].id, 'occurrence').subscribe( (ans) => {
          if (ans.length === 0) {
            data[j].file = undefined;
          } else {
            data[j].file = ans;
          }
        });
      }
      this.dataOccurrence = new MatTableDataSource<Occurrence>(data);
    });
  }

  approveOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência enviada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  receiveFile($event) {
    this.getListOccurrence();
  }

  resolveOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;

    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência atualizada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  removeOccurrence(element: any) {
    this.mdrService.deleteOccurrence(element).subscribe((data) => {
      this.getListOccurrence();
    });
  }

  isArray(val) {return typeof val === 'object'; }
  getSafeURL(element: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base_url + element.fileName);
  }
  getUOCValue() {
    this.mdrService.getUOCValue(this.occurrence, this.mdr).subscribe( (data) => {
      this.occurrence.value = data;
    });
  }

  openDialog(occurrence: Occurrence): void {
    const dialogRef = this.dialog.open(AcceptOccurrenceDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        copq: this.copq,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
      if (response !== undefined) {
        occurrence.resolution = response;
        this.resolveOccurrence(occurrence);
      }
    });
  }

  rejectOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_DENIED;

    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência negada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

}

@Component({
  selector: 'app-accept-occurrence-dialog',
  templateUrl: 'accept-occurrence-dialog.html',
  styleUrls: ['./occurrence.component.scss']
})
export class AcceptOccurrenceDialogComponent {
  isUOC = false;
  isCOPQ = false;
  copqSelected: any;
  uocSelected: any;
  valueUOC: any;
  mdr: any;
  totalValue = 0;
  uoc: UOC[];
  uocMult = 1;
  copqFinalValue = 0;
  copqMult = 1;
  valueCOPQ = 0;
  copq: Copq[];
  drList: DR[];
  myControl = new FormControl();
  occ: any;

  constructor(
    public dialogRef: MatDialogRef <AcceptOccurrenceDialogComponent>,
    public mdrService: MdrService,
    public uocObservable: UOCObservable,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    // pega UOCs possíveis
    this.mdrService.getListUOC().subscribe((response) => {
      this.uoc = response;
    });
    this.data.needsAttachment = false;
    this.copq = data.copq;
    this.drList = data.drList;
    this.occ = data.occ;
    this.uocSelected = this.occ.uoc;
  }

  onYesClick(): void {
    // se é UOC
    if (this.isUOC === true) {
      if (this.myControl.value === null) {
        this.snackBar.open('Preencha pelo menos uma DR', 'Ok', {
          duration: 4000,
        });
        return;
      }
      // nao permite criar UOC com value 0
      if ( (this.valueUOC * this.uocMult) === 0 || isNaN(this.valueUOC * this.uocMult) ) {
        this.snackBar.open('Não é possível criar UOC sem valor', 'Ok', {
          duration: 4000,
        });
        return;
      }
      const uoc = new UOCMDR();
      uoc.idMDR = this.data.mdr.id;
      uoc.value = this.valueUOC * this.uocMult * 100;
      uoc.uoc = this.uocSelected;
      uoc.needsAttachment = this.data.needsAttachment;
      uoc.idOccurrence = this.occ.id;
      for (let i = 0; i < this.myControl.value.length; i++) {
        for (let j = 0; j < this.drList.length; j++) {
          if (this.drList[j].id === this.myControl.value[i]) {
            uoc.drList.push(this.drList[j]);
          }
        }
      }
      // cria a UOC
      this.mdrService.createUOC(uoc).subscribe((responseUOC) => {
        // atualiza lista de UOCs da MDR
        this.mdrService.getUOC(this.data.mdr).subscribe((uocmdr) => {
          this.uocObservable.changeUOC(uocmdr);
        });
        // se tambem é CoPQ
        if (this.isCOPQ === true) {
          const copq = new COPQMDR();
          copq.idMDR = this.data.mdr.id;
          copq.value = this.valueCOPQ * this.copqMult * 100;
          copq.copq = this.copqSelected;
          this.mdrService.createCoPQ(copq).subscribe((responseCOPQ) => {
            // fecha dialog
            this.dialogRef.close(this.data.text);
          });
        } else { // nao é CoPQ
          // fecha dialog
          this.dialogRef.close(this.data.text);
        }
      });
    } else { // nao é UOC
      // fecha dialog
      this.dialogRef.close(this.data.text);
    }
  }

  getUOCValue(elem) {
    // console.log('teste')
    this.mdrService.getUOCValue(elem, this.data.mdr).subscribe((data) => {
      this.valueUOC = data;
      // console.log(data);
    });
  }

  onNoClick(): void {
    this.data = 0;
    this.dialogRef.close();
  }

}
