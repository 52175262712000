<h1 mat-dialog-title>{{ 'TYPE-MDR-DIALOG/title' | translate}}</h1>
<div mat-dialog-content>
  <form #form="ngForm">
    <mat-select placeholder="Type" [(ngModel)]="typeMDR" name="typeMDR" required>
      <mat-option *ngFor="let elem of types" [value]="elem.id"  >
        {{ elem.name }}
      </mat-option>
    </mat-select>
    <div *ngIf="typeMDR === 'CMS'">
      <mat-checkbox [(ngModel)]="returnLeg" name="returnLeg">{{'TYPE-MDR-DIALOG/return-leg' | translate}}</mat-checkbox>
    </div>
    <div class="col-md-12">
      <mat-form-field [ngClass]="{hidden: typeMDR !== 'DTR'}" style="width: calc(100%);">
        <input matInput #search type="text"  autocapitalize="off" spellcheck="off"
          placeholder="{{ 'NEW-DELIVERY-REQUEST/text/delivery-address' | translate }}"
          id="deliveryAddress"
          [(ngModel)]="address"
          name="address">
      </mat-form-field>
    </div>
    <div *ngIf="typeMDR === 'DTR'">
      <p>{{'TYPE-MDR-DIALOG/add-address-title' | translate }}</p>
      <button mat-button class="primary-button w-100" (click)="addAddress()">{{'TYPE-MDR-DIALOG/add-address' |translate }}</button>
      <mat-list dense>
        <mat-list-item *ngFor="let elem of listAddresses; let i=index">
          <p matLine>{{ elem.address }}</p>
          <i matListIcon class="fa fa-times" (click)="removeAddress(i)"></i>
        </mat-list-item>
      </mat-list>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onYesClick()" class="primary-button" [disabled]="!form.valid">Sim</button>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Não</button>
</div>
