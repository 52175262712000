import * as i0 from "@angular/core";
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
export class XlsxService {
    constructor() { }
    export(json, excelFileName) {
        // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // this.save(excelBuffer, excelFileName);
    }
    save(buffer, fileName) {
        const data = new Blob([buffer], { type: EXCEL_TYPE });
        // adiciona extensao
        if (fileName.lastIndexOf(EXCEL_EXTENSION) !== (fileName.length - EXCEL_EXTENSION.length)) {
            fileName = fileName + EXCEL_EXTENSION;
        }
        // adiciona timestamp
        if (fileName.indexOf('%') !== -1) {
            fileName = fileName.replace('%', String(new Date().getTime()));
        }
        // FileSaver.saveAs(data, fileName);
    }
    import(input) {
        // let workbook;
        // let excelInJSON;
        const fileReader = new FileReader();
        // init read
        fileReader.readAsArrayBuffer(input.target.files[0]);
        // return new Observable((observer) => {
        //   // if success
        //   fileReader.onload = ev => {
        //     let binary = '';
        //     const bytes = new Uint8Array((<any>ev.target).result);
        //     const length = bytes.byteLength;
        //     for (let i = 0; i < length; i++) {
        //       binary += String.fromCharCode(bytes[i]);
        //     }
        //     // Converts the excel data in to json
        //     workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
        //     // only first sheet
        //     excelInJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        //     observer.next(excelInJSON);
        //   };
        //   // if failed
        //   fileReader.onerror = error => observer.error(error);
        // });
        return;
    }
}
XlsxService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function XlsxService_Factory() { return new XlsxService(); }, token: XlsxService, providedIn: "root" });
