/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./form-upload.component";
var styles_FormUploadComponent = [i0.styles];
var RenderType_FormUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormUploadComponent, data: {} });
export { RenderType_FormUploadComponent as RenderType_FormUploadComponent };
function View_FormUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ":"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_FormUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("FORM_UPLOAD/txt/no-file")); _ck(_v, 1, 0, currVal_0); }); }
function View_FormUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selecionado; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormUploadComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["class", "file-input"], ["type", "file"], ["value", "selecionado"]], [[8, "id", 0], [8, "name", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "span", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormUploadComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormUploadComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); var currVal_6 = !_co.selecionado; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.selecionado; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ("fileInput-" + _co.name); var currVal_2 = _co.name; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 5).disabled || null); var currVal_4 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("FORM_UPLOAD/button")); _ck(_v, 6, 0, currVal_5); }); }
export function View_FormUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-upload", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).emitFiles($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FormUploadComponent_0, RenderType_FormUploadComponent)), i1.ɵdid(1, 49152, null, 0, i8.FormUploadComponent, [i1.ElementRef], null, null)], null, null); }
var FormUploadComponentNgFactory = i1.ɵccf("app-form-upload", i8.FormUploadComponent, View_FormUploadComponent_Host_0, { label: "label", name: "name" }, { setFile: "setFile" }, []);
export { FormUploadComponentNgFactory as FormUploadComponentNgFactory };
