import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {DR} from '../../../models/dr';
import {MatSnackBar} from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { DrService } from '../../../providers/dr.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

declare var google: any;

@Component({
  selector: 'app-dr-validation',
  templateUrl: './dr-validation.component.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DrValidationComponent implements OnInit {
  id;
  public dr: DR = new DR();
  salesOrders;
  @ViewChild('addrSearch', {static: false})
  public searchElementRef: ElementRef;

  // pro icon de salesOrders
  status = null;
  icons = {
    'status' : '',
    'tooltips': {
      'invalid': 'OVs inválidas',
      'incomplete': 'NFs não existentes',
      'valid': 'DR Booking válido',
      'undefined': ''
    },
    'icons' : {
      'invalid': 'fa-exclamation-circle',
      'incomplete': 'fa-exclamation-triangle',
      'valid': 'fa-check',
      'undefined': ''
    },
    'classes' : {
      'invalid' : 'icon-red',
      'incomplete': 'icon-yellow',
      'valid': 'icon-green',
      'undefined': ''
    }
  };

  constructor(
    public router: Router,
    public aRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private drService: DrService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe( (params) => {
      this.id = params.id;
      this.validateDR();
    });
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = lat;
        this.dr.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
      }
    });
  }

  convertLatLngToAddress() {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {lat: parseFloat(lat), lng: parseFloat(lng)}}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
      }
    });
  }

  validateDR() {
    this.drService.getValidation(this.id).subscribe((response) => {
      this.dr = response.dr;
      this.salesOrders = this.dr.salesOrders.join(',');
      this.icons.status = response.status;
      this.status = {
        status: response.status,
        error: response.error,
        warning: response.warning
      };

      if ( response.status === 'valid' ) {
        this.approveDR();
      } else {
        this.openDialog();
      }
    }, (err) => {
      this.snackBar.open('Erro ao buscar DR Booking', 'Ok', {duration: 4000});
      this.router.navigate(['dr-booking']);
    });
  }

  saveAndValidate() {
    this.dr.salesOrders = this.salesOrders
                              .replace(/ /g, ',')   // troca espaços por vírgulas
                              .split(',')          // quebra em array nas vírgulas
                              .filter(e => e.length); // remove strings vazias
    this.drService.updateBooking(this.id, this.dr).subscribe((response) => {
      this.validateDR();
    }, (err) => {
      this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', {duration: 2000});
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogDrValidationComponent, {
      data: {
        status: this.status
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'approve' ) {
        this.approveDR();
      }
    });
  }

  approveDR() {
    this.drService.updateBookingToDR(this.id).subscribe((response) => {
      this.router.navigate(['delivery-optimizer']);
    }, (err) => {
      this.snackBar.open('Erro ao criar DR a partir de DR Booking', 'Ok', {duration: 2000});
    });
  }

}

@Component({
  selector: 'app-dialog-dr-validation',
  templateUrl: 'dialog-dr-validation.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DialogDrValidationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDrValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickOk(): void {
    this.dialogRef.close({ what: 'approve' });
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }

}
