<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'DR_BOOKING/title' | translate }}</p>
  <p class="mx-4">{{ 'DR_BOOKING/sub-title' | translate }}</p>
</div>

<div class="colored-background pt-5">
  <mat-card class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <form #form="ngForm" class="d-flex flex-wrap">
      <mat-form-field class="col-md-12">
        <i matSuffix
          class="fa"
          [ngClass]="icons.status ? [ icons.icons[icons.status] , icons.classes[icons.status] ] : ''"
          [matTooltip]="icons.tooltips[icons.status]"
          (click)="openDialog()"
        ></i>
        <input matInput required
          placeholder="{{ 'DR_BOOKING/text/sales-orders' | translate }}"
          [(ngModel)]="salesOrders"
          name="dr.salesOrders">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required #addrSearch
          type="text"
          autocapitalize="off"
          spellcheck="off"
          placeholder="{{ 'DR_BOOKING/text/delivery-address' | translate }}"
          name="deliveryAddress"
          id="deliveryAddress"
          [(ngModel)]="dr.deliveryAddress"
          (change)="convertAddressToLatLng()">
      </mat-form-field>
      <div class="col-md-6 d-flex flex-row p-0">
        <mat-form-field class="col-md-6">
          <input matInput required
            placeholder="{{ 'DR_BOOKING/text/lat' | translate }}"
            id="latitudeAddress"
            name="latitudeAddress"
            [(ngModel)]="dr.lat"
            (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput required
            placeholder="{{ 'DR_BOOKING/text/lng' | translate }}"
            id="longitudeAddress"
            name="longitudeAddress"
            [(ngModel)]="dr.lng"
            (change)="convertLatLngToAddress()">
        </mat-form-field>
      </div>
      <mat-form-field class="col-md-6">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/name-receptor' | translate }}" [(ngModel)]="dr.receiverName" name="receiverName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required mask="(00) 00009-0000" type="text" placeholder="{{ 'DR_BOOKING/text/cellphone-recept' | translate }}" [(ngModel)]="dr.receiverPhone" name="receiverPhone">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/name-supervisor' | translate }}" [(ngModel)]="dr.supervisorName" name="dr.supervisorName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required mask="(00) 00009-0000"  placeholder="{{ 'DR_BOOKING/text/cellphone-higher' | translate }}" [(ngModel)]="dr.supervisorPhone" name="dr.supervisorPhone">
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input matInput required name="dateCollect" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" [placeholder]="'DR_BOOKING/text/date' | translate" [(ngModel)]="dr.dateScheduling">
        <owl-date-time #dt></owl-date-time>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/site' | translate }}" [(ngModel)]="dr.site" name="dr.site">
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/infra' | translate }}" [(ngModel)]="dr.infraType" name="dr.infraType">
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/unification-id' | translate }}" [(ngModel)]="dr.unificationId" name="unificationId">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/site-team' | translate }}" [(ngModel)]="dr.siteTeam" name="siteTeam">
      </mat-form-field>

      <mat-form-field class="col-md-12">
        <textarea matInput placeholder="{{ 'DR_BOOKING/text/obs' | translate }}" [(ngModel)]="dr.obs" name="dr.obs"></textarea>
      </mat-form-field>
      <!-- fim preenchimento campos -->

    </form>
    <button mat-raised-button class="w-100 primary-button" [disabled]="!form.valid" (click)="saveAndValidate()">{{'DR_BOOKING/btn/validate-dr' | translate}}</button>
  </mat-card>
</div>
