import { Component, OnInit } from '@angular/core';
import { MatCssHueColorContrastMapItem, MaterialCssVarsService } from 'angular-material-css-vars';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { HttpEventType } from '@angular/common/http';
import { ConfigurationService } from '../../providers/configuration.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  color;
  palettePrimary: MatCssHueColorContrastMapItem[];
  topicForm = new FormGroup({
    image: new FormControl('', [Validators.required])
  });
  constructor(public materialCssVarsService: MaterialCssVarsService,
    private configurationService: ConfigurationService) {
  }

  ngOnInit() {
  }
  setConfigurations() {
    this.configurationService.saveColor(this.color).subscribe(data => {
    });
    this.configurationService.saveLogoImage(this.topicForm.value.image).subscribe(data => {
    });
  }



  onPrimaryChange(hex: string) {
    this.materialCssVarsService.setPrimaryColor(hex);
    this.color = hex;
    this.palettePrimary = this.materialCssVarsService.getPaletteWithContrastForColor(hex);
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
