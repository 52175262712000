<script src="login.component.ts"></script>
<div class="background" color="primary">
  <div style="width:100%;position: absolute">
    <img style="width: 200px; display: block; margin-left: auto; margin-right: auto;" src="../../assets/images/aware-logo.png">
  </div>
  <mat-card class="central">
    <p>Email: {{email}}</p>
    <mat-form-field >
      <input matInput type="password" [(ngModel)]="password" placeholder="{{ 'PASSWORD' | translate }}" >
    </mat-form-field>
    <mat-form-field >
      <input matInput type="password" [(ngModel)]="confimPassword" placeholder="{{ 'CONFIRMPASSWORD' | translate }}" >
    </mat-form-field>
    <div>
      <re-captcha class="captcha" (resolved)="resolved($event)" siteKey="6LeY3dMUAAAAAPVk-bNlgXs54hFyVNioQjAikjLi"></re-captcha>
    </div>
    <div>
      <button class="login" mat-raised-button (click)="sendPassword()">{{ 'SEND' | translate }}</button>
    </div>
  </mat-card>
  <div style="width:100%;position: absolute;bottom: 0px;">
    <p style="width: 230px; display: block; margin-left: auto; margin-right: auto; color: white;">   Sistema de Logística Aware ® </p>
  </div>
</div>
