var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnChanges } from '@angular/core';
import { MdrService } from '../../../providers/mdr.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { DrService } from '../../../providers/dr.service';
import { HttpClient } from '@angular/common/http';
import { DrObservable } from '../../../services/dr.service';
import { MdrObservable } from '../../../services/mdr.service';
import { Legs, Pin } from '../../../models/legs';
import { MDR } from '../../../models/mdr';
export class GeolocationComponent {
    constructor(drService, mdrService, aRoute, http, drObservable, mdrObservable, snackBar) {
        this.drService = drService;
        this.mdrService = mdrService;
        this.aRoute = aRoute;
        this.http = http;
        this.drObservable = drObservable;
        this.mdrObservable = mdrObservable;
        this.snackBar = snackBar;
        this.lat = -23.152493;
        this.lng = -45.7906075;
        this.latNew = -23.152493;
        this.lngNew = -45.7906075;
        this.distanceByLeg = [];
        this.i = 0;
        this.typeTransp = [{ name: 'Rodoviário', value: 0 }, { name: 'Balsa', value: 1 }, { name: 'Transporte Aéreo', value: 2 }];
        this.renderOptions = {
            suppressMarkers: true,
            draggable: false,
            polylineOptions: { strokeColor: '#AAA', zIndex: 1 },
        };
        this.renderOptionsDriver = {
            suppressMarkers: true,
            draggable: false,
            polylineOptions: { strokeColor: '#00f', zIndex: 2 },
        };
        this.show = true;
        this.waypoints = [];
        // when OnDestroy is called.
        this.data = [];
        this.legsSource = [];
        this.legsNewLegs = [];
        this.directions = null;
        this.markers = [];
        this.markersDriver = [];
        this.showMarkers = false;
        this.legsToDirection = [];
        this.dataSource = new MatTableDataSource();
        this.dataSourcePin = new MatTableDataSource();
        this.displayedColumns = ['pointStart', 'pointEnd', 'type', 'remove'];
        this.displayedColumnsPin = ['lat', 'lng', 'label'];
        this.haveDriver = false;
        this.showMap = true;
        this.icon = {
            url: './assets/images/red_truck.png',
            scaledSize: {
                height: 40,
                width: 40
            },
        };
        // relativos aos pontos do tracking
        this.colorPrefix = 'COLOR/';
        this.coloredPoint = [
            { scaledSize: { height: 5, width: 5 }, color: 'red', url: './assets/images/red_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'blue', url: './assets/images/blue_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'green', url: './assets/images/green_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'pink', url: './assets/images/pink_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'purple', url: './assets/images/purple_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'orange', url: './assets/images/orange_point.png' }
        ];
        this.pickupAddress = 'R. Ambrósio Molina, 1090 - Eugênio de Melo, São José dos Campos - SP, 12247-000';
        // mdr.status para os quais considera-se que mdr foi finalizada
        this.finishedDriveStatus = ['Carga entregue com sucesso',
            'Carga retornada total',
            'Entrega de carga certificada pelo LSP',
            'MDR paga / concluída'
        ];
        // colunas para mostrar posições dos motoristas
        this.driverPositionTableColumns = [
            { value: 'createdAt', name: 'GEOLOCATION/table/time' },
            { value: 'lat', name: 'GEOLOCATION/table/lat' },
            { value: 'lng', name: 'GEOLOCATION/table/lng' },
            { value: 'speed', name: 'GEOLOCATION/table/speed' },
            { value: 'phoneNumber', name: 'GEOLOCATION/table/phone' },
            { value: 'color', name: 'GEOLOCATION/table/color' }
        ];
        this.markersTruck = [];
        this.alive = true;
    }
    ngOnChanges() {
        // quando a MDR for atualizada
        if (this.mdr.id) {
            this.getLegsDelete();
            this.getDriverLastPosition();
            this.getDriverPosition();
        }
    }
    onResponse(event) {
        // console.log(event);
        // this.distanceRemaining = event.routes[0].legs[0].distance.text;
        // this.timeRemaining = event.routes[0].legs[0].duration.text;
        this.legsNewLegs.forEach((leg) => {
            if (Math.abs(leg.pointInit.lat - event.routes[0].legs[0].start_location.lat()) < 0.01 &&
                Math.abs(leg.pointInit.lng - event.routes[0].legs[0].start_location.lng()) < 0.01 &&
                Math.abs(leg.pointEnd.lat - event.routes[0].legs[0].end_location.lat()) < 0.01 &&
                Math.abs(leg.pointEnd.lng - event.routes[0].legs[0].end_location.lng()) < 0.01) {
                leg.distance = event.routes[0].legs[0].distance.value;
                leg.ETA = event.routes[0].legs[0].duration.value;
            }
        });
        this.updateETA();
    }
    onResponseDriver(event) {
        // console.log(event);
        const dist = event.routes[0].legs[0].distance.value;
        const ETA = event.routes[0].legs[0].duration.value;
        this.distanceRemaining = Math.floor(dist / 1000) + ' km';
        this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.floor((ETA % 3600) / 60) + ' min';
    }
    updateETA() {
        let dist = 0;
        let ETA = 0;
        this.legsNewLegs.forEach((leg) => {
            if (leg.type === 'Rodoviário') {
                dist += leg.distance;
                ETA += leg.ETA;
            }
        });
        this.distanceRemaining = Math.floor(dist / 1000) + ' km';
        this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.floor((ETA % 3600) / 60) + ' min';
    }
    removeLeg(legToRemove) {
        let flag = -1;
        for (let i = 0; i < this.legsNewLegs.length; i++) {
            if (this.legsNewLegs[i] === legToRemove) {
                flag = i;
                // console.log(flag)
            }
        }
        this.mdrService.removeLeg(legToRemove, this.mdr.id).subscribe((response) => {
            if (flag - 1 > -1 && flag + 1 < this.legsNewLegs.length) {
                this.legsNewLegs[flag - 1].pointEnd = legToRemove.pointEnd;
                this.mdrService.putLegs(this.legsNewLegs[flag - 1], this.mdr.id).subscribe((data) => {
                    this.dataSource = new MatTableDataSource(this.legsNewLegs);
                    this.getLegsDelete();
                    this.updateETA();
                });
                this.legsNewLegs[flag + 1].pointInit = legToRemove.pointInit;
                this.mdrService.putLegs(this.legsNewLegs[flag - 1], this.mdr.id).subscribe((data) => {
                    this.dataSource = new MatTableDataSource(this.legsNewLegs);
                    this.getLegsDelete();
                    this.updateETA();
                });
            }
            this.getLegsDelete();
            this.updateETA();
        });
    }
    deletePin() {
        // console.log('Remove:');
        // console.log(this.markerDelete);
        let flag = -1;
        for (let i = 0; i < this.markers.length; i++) {
            // console.log(this.markers[i].label);
            if (this.markers[i].label === this.markerDelete) {
                flag = i;
            }
        }
        // console.log(flag);
        // console.log(this.legsNewLegs);
        if (flag !== -1) {
            const markerInit = this.legsNewLegs[flag].pointInit;
            this.mdrService.removeLeg(this.legsNewLegs[flag], this.mdr.id).subscribe((data) => {
                this.getLegsDelete();
                this.updateETA();
                this.dataSource = new MatTableDataSource(this.legsNewLegs);
            });
            if (flag - 1 > -1) {
                this.mdrService.removeLeg(this.legsNewLegs[flag - 1], this.mdr.id).subscribe((data) => {
                    this.getLegsDelete();
                    this.updateETA();
                    this.dataSource = new MatTableDataSource(this.legsNewLegs);
                });
            }
        }
    }
    addPin() {
        // console.log(this.latNew);
        // console.log(this.lngNew);
        // console.log(this.markers);
        if (this.latNew !== null && this.lngNew !== null) {
            this.markers.push({ lat: this.latNew, lng: this.lngNew, label: String.fromCharCode(this.markers.length + 65), title: '' });
            this.dataSourcePin = new MatTableDataSource(this.markers);
            this.snackBar.open('Ponto adicionado', 'Fechar', {
                duration: 2000,
            });
        }
    }
    updateMarker(event, mark) {
        this.legsToDirection = [];
        this.markers.forEach((m) => {
            if (m.label === mark.label) {
                m.lat = event.coords.lat;
                m.lng = event.coords.lng;
            }
        });
        this.legsNewLegs.forEach((leg) => {
            if (leg.pointInit.label === mark.label) {
                leg.pointInit.lat = event.coords.lat;
                leg.pointInit.lng = event.coords.lng;
            }
            if (leg.pointEnd.label === mark.label) {
                leg.pointEnd.lat = event.coords.lat;
                leg.pointEnd.lng = event.coords.lng;
            }
            this.legsToDirection.push(leg);
        });
        // console.log(this.legsNewLegs);
        // console.log(this.markers);
        this.saveRoute();
    }
    createLeg() {
        // console.log('Version 1.0');
        const nLeg = new Legs();
        // console.log("error");
        // console.log(this.markerInit);
        for (let i = 0; i < this.markers.length; i++) {
            // console.log(this.markers[i]);
            if (this.markers[i].label === this.markerInit) {
                nLeg.pointInit = this.markers[i];
                if (this.legsNewLegs.length > i) {
                    nLeg.order = this.legsNewLegs[i].order + 1;
                }
            }
            else if (this.markers[i].label === this.markerEnd) {
                nLeg.pointEnd = this.markers[i];
                if (this.legsNewLegs.length > i) {
                    nLeg.order = this.legsNewLegs[i].order - 1;
                }
            }
        }
        if (nLeg.order === null) {
            nLeg.order = 0;
        }
        const letterEndtoEnd = this.legsNewLegs.filter(legs => (nLeg.pointEnd.lat === legs.pointEnd.lat && nLeg.pointEnd.lng === legs.pointEnd.lng)).map(legs => legs.pointEnd.label);
        const letterEndtoInit = this.legsNewLegs.filter(legs => (nLeg.pointEnd.lat === legs.pointInit.lat && nLeg.pointEnd.lng === legs.pointInit.lng)).map(legs => legs.pointInit.label);
        const letterInittoInit = this.legsNewLegs.filter(legs => (nLeg.pointInit.lat === legs.pointInit.lat && nLeg.pointInit.lat === legs.pointInit.lng)).map(legs => legs.pointInit.label);
        const letterInittoEnd = this.legsNewLegs.filter(legs => (nLeg.pointInit.lat === legs.pointEnd.lat && nLeg.pointInit.lat === legs.pointEnd.lng)).map(legs => legs.pointEnd.label);
        // console.log(letterEndtoEnd);
        // console.log(letterEndtoInit);
        // console.log(letterInittoInit);
        // console.log(letterInittoEnd);
        // console.log(nLeg);
        nLeg.type = this.selectedType;
        if (this.legsNewLegs.length === 0) {
            nLeg.type = this.selectedType;
            this.legsNewLegs.push(nLeg);
            // console.log(this.legsNewLegs);
            this.legsToDirection.push(nLeg);
            this.saveRoute();
        }
        else {
            if (nLeg.pointEnd.label === this.legsNewLegs[0].pointInit.label || nLeg.pointInit.label === this.legsNewLegs[this.legsNewLegs.length - 1].pointEnd.label) {
                this.legsNewLegs.push(nLeg);
                // console.log(this.legsNewLegs);
                this.legsToDirection.push(nLeg);
                this.saveRoute();
            }
            else {
                this.snackBar.open('Leg invalida', 'Fechar', {
                    duration: 2000,
                });
            }
        }
    }
    removeDirection() {
        this.showMap = false;
    }
    showDirection() {
        this.showMap = true;
    }
    getLegs() {
        this.legsNewLegs = [];
        this.markers = [];
        if (this.legsNewLegs.length === 0) {
            this.mdrService.getLegs(this.mdr.id).subscribe((data) => {
                // console.log(data);
                // console.log('legs');
                data.forEach((leg, i) => {
                    // console.log(leg);
                    const legNew = new Legs();
                    legNew.id = leg.id;
                    const letterInittoInit = this.legsNewLegs.filter(legs => (parseFloat(leg.pointInit.lat) === legs.pointInit.lat && parseFloat(leg.pointInit.lng) === legs.pointInit.lng)).map(legs => legs.pointInit.label);
                    const letterInittoEnd = this.legsNewLegs.filter(legs => (parseFloat(leg.pointInit.lat) === legs.pointEnd.lat && parseFloat(leg.pointInit.lng) === legs.pointEnd.lng)).map(legs => legs.pointEnd.label);
                    const letterEndtoEnd = this.legsNewLegs.filter(legs => (parseFloat(leg.pointEnd.lat) === legs.pointEnd.lat && parseFloat(leg.pointEnd.lng) === legs.pointEnd.lng)).map(legs => legs.pointEnd.label);
                    const letterEndtoInit = this.legsNewLegs.filter(legs => (parseFloat(leg.pointEnd.lat) === legs.pointInit.lat && parseFloat(leg.pointEnd.lng) === legs.pointInit.lng)).map(legs => legs.pointInit.label);
                    // console.log('letter');
                    // console.log(this.legsNewLegs);
                    // console.log(leg);
                    // console.log(letterInittoInit);
                    // console.log(letterInittoEnd);
                    // console.log(letterEndtoEnd);
                    // console.log(letterEndtoInit);
                    if (letterInittoInit.length > 0) {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = letterInittoInit[0];
                    }
                    else if (letterInittoEnd.length === 0 && i !== 0) {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = String.fromCharCode(i + 1 + 65);
                        this.markers.push({
                            lat: parseFloat(leg.pointInit.lat),
                            lng: parseFloat(leg.pointInit.lng),
                            label: String.fromCharCode(i + 1 + 65),
                            title: '',
                        });
                    }
                    else {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = String.fromCharCode(i + 65);
                    }
                    if (letterEndtoEnd.length > 0) {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = letterEndtoEnd[0];
                    }
                    else if (letterEndtoInit.length > 0) {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = letterEndtoInit[0];
                    }
                    else {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = String.fromCharCode(i + 1 + 65);
                        this.markers.push({
                            lat: parseFloat(leg.pointEnd.lat),
                            lng: parseFloat(leg.pointEnd.lng),
                            label: String.fromCharCode(i + 1 + 65),
                            title: '',
                        });
                    }
                    legNew.type = leg.type;
                    legNew.order = leg.order;
                    // console.log(leg.type);
                    this.legsNewLegs.push(legNew);
                    // console.log("lala");
                    // console.log(this.markers);
                });
                // console.log('Markers and Legs:');
                // console.log(this.markers);
                // console.log(this.legsNewLegs);
            });
            this.showMarkers = true;
            this.updateETA();
            this.dataSource = new MatTableDataSource(this.legsNewLegs);
        }
    }
    getLegsDelete() {
        return __awaiter(this, void 0, void 0, function* () {
            this.mdrService.getLegs(this.mdr.id).subscribe((data) => {
                this.legsNewLegs = [];
                this.markers = [];
                data.forEach((leg, i) => {
                    const legNew = new Legs();
                    legNew.id = leg.id;
                    const letterInittoInit = this.legsNewLegs.filter(legs => {
                        return (parseFloat(leg.pointInit.lat) === legs.pointInit.lat && parseFloat(leg.pointInit.lng) === legs.pointInit.lng);
                    }).map(legs => legs.pointInit.label);
                    const letterInittoEnd = this.legsNewLegs.filter(legs => {
                        return (parseFloat(leg.pointInit.lat) === legs.pointEnd.lat && parseFloat(leg.pointInit.lng) === legs.pointEnd.lng);
                    }).map(legs => legs.pointEnd.label);
                    const letterEndtoEnd = this.legsNewLegs.filter(legs => {
                        return (parseFloat(leg.pointEnd.lat) === legs.pointEnd.lat && parseFloat(leg.pointEnd.lng) === legs.pointEnd.lng);
                    }).map(legs => legs.pointEnd.label);
                    const letterEndtoInit = this.legsNewLegs.filter(legs => {
                        return (parseFloat(leg.pointEnd.lat) === legs.pointInit.lat && parseFloat(leg.pointEnd.lng) === legs.pointInit.lng);
                    }).map(legs => legs.pointInit.label);
                    // console.log('letter');
                    // console.log(this.legsNewLegs);
                    // console.log(leg);
                    // console.log(letterInittoInit);
                    // console.log(letterInittoEnd);
                    // console.log(letterEndtoEnd);
                    // console.log(letterEndtoInit);
                    if (i === 0) {
                        // console.log("lala");
                        this.markers.push({
                            lat: parseFloat(leg.pointInit.lat),
                            lng: parseFloat(leg.pointInit.lng),
                            label: 'A',
                            title: '',
                        });
                    }
                    if (letterInittoInit.length > 0) {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = letterInittoInit[0];
                    }
                    else if (letterInittoEnd.length === 0 && i !== 0) {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = String.fromCharCode(i + 1 + 65);
                        this.markers.push({
                            lat: parseFloat(leg.pointInit.lat),
                            lng: parseFloat(leg.pointInit.lng),
                            label: String.fromCharCode(i + 1 + 65),
                            title: '',
                        });
                    }
                    else {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = String.fromCharCode(i + 65);
                    }
                    if (letterEndtoEnd.length > 0) {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = letterEndtoEnd[0];
                    }
                    else if (letterEndtoInit.length > 0) {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = letterEndtoInit[0];
                    }
                    else {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = String.fromCharCode(i + 1 + 65);
                        this.markers.push({
                            lat: parseFloat(leg.pointEnd.lat),
                            lng: parseFloat(leg.pointEnd.lng),
                            label: String.fromCharCode(i + 1 + 65),
                            title: '',
                        });
                    }
                    legNew.type = leg.type;
                    legNew.order = leg.order;
                    // console.log(leg.type);
                    this.legsNewLegs.push(legNew);
                });
            });
            this.showMarkers = true;
            this.updateETA();
            this.dataSource = new MatTableDataSource(this.legsNewLegs);
            this.showMap = false;
            yield setTimeout(() => { this.showMap = true; }, 1000);
        });
    }
    saveRoute() {
        for (const i of Object.keys(this.legsNewLegs)) {
            // console.log(this.legsNewLegs[i]);
            this.mdrService.putLegs(this.legsNewLegs[i], this.mdr.id).subscribe((data) => {
                if (parseInt(i, 10) === this.legsNewLegs.length - 1) {
                    // console.log('lal');
                    this.getLegsDelete();
                    this.updateETA();
                }
            });
        }
        this.snackBar.open('Alterações Salvas', 'Fechar', {
            duration: 2000,
        });
    }
    // pega última posição da MDR
    getDriverLastPosition() {
        // pega última posição da MDR
        this.mdrService.getDriverLastPosition(this.mdr).subscribe((elem) => {
            if (elem !== undefined && !!elem) {
                this.driverPosition = new Pin();
                this.driverPosition.lat = parseFloat(elem.lat);
                this.driverPosition.lng = parseFloat(elem.lng);
                this.destination = new Pin();
                this.haveDriver = true;
            }
        });
    }
    // pega posições do tracking
    getDriverPosition() {
        this.markersTruck = [];
        this.mdrService.getDriverPosition(this.mdr.id).subscribe((driverList) => {
            // monta lista de posições pra tabela e última posição do motorista
            this.driverPositionList = [];
            driverList.forEach((driver, i) => {
                // pra cada posição do motorista
                driver['positions'].forEach((position) => {
                    // adiciona o telefone do motorista às posições
                    position['phoneNumber'] = driver['phoneNumber'];
                    // converte velocidade em km/h
                    position['speed'] = position['speed'] * 3.6;
                    // converte createdAt em Date
                    position['createdAt'] = new Date(position['createdAt']);
                    // escolhe cor para as posições
                    const colorI = i % this.coloredPoint.length;
                    position['color'] = this.colorPrefix + this.coloredPoint[colorI].color;
                    position['icon'] = this.coloredPoint[colorI];
                });
                // adiciona posições à lista de latlngs
                this.driverPositionList = [].concat(this.driverPositionList, driver['positions']);
            });
            // monta lista de markers pro mapa
            this.driverPositionList.forEach((position) => {
                this.markersDriver.push({
                    lat: parseFloat(position['lat']),
                    lng: parseFloat(position['lng']),
                    label: '',
                    title: position['phoneNumber'],
                    icon: position['icon']
                });
            });
        });
    }
}
export class Position {
    constructor() {
        this.lat = null;
        this.lng = null;
    }
}
export class WayPoint {
    constructor() {
        this.location = new Position();
        this.stopover = false;
    }
}
