<app-header-without-login class="mat-elevation-z3"></app-header-without-login>
<div class="mx-5">
  <p class="mat-h1">
    {{ 'LOGISTIC-ORDER/title/info-request' | translate }}: {{ mdr.pickupID ? mdr.pickupID : ('AW-' + ('0000000'+mdr.id).slice(-6)) }}
  </p>
  <p class="blue">
    {{ 'GEOLOCATION/title' | translate }}
  </p>
  <mat-divider></mat-divider>

  <app-geolocation-without-login></app-geolocation-without-login>
  <app-dr-card-without-login></app-dr-card-without-login>

  <!-- informações da MDR -->
  <div class="d-flex justify-content-center align-items-center row">
    <table>
      <tr>
        <th>{{'WITHOUT_LOGIN/ovs'|translate}}</th>
        <th>{{'WITHOUT_LOGIN/sites'|translate}}</th>
      </tr>
      <tr>
        <td>{{mdr.ovs.length}}</td>
        <td>{{mdr.sites.length}}</td>
      </tr>
    </table>
  </div>
</div>
