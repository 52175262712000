<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'DELIVERY_REQUEST' | translate }}</p>
  <p class="mx-4">{{ 'DELIVERY_REQUEST/txt1' | translate }}<br>{{ 'DELIVERY_REQUEST/txt2' | translate }}</p>
</div>
<div class="colored-background table-background">
  <div class="mx-5">
    <div class="m-0 d-flex justify-content-between">
      <mat-form-field class="my-3 pt-1 pl-4 rounded-corners col-md-8" floatLabel="never">
        <mat-label><i class="fa fa-search mr-1"></i>{{ 'DELIVERY_REQUEST/search' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
      <button mat-raised-button class="rounded-corners my-3 pt-1 pl-4 col-md-3" color="accent" routerLink="/new-delivery-request"><i class="fa fa-plus mr-4"></i>{{ 'DELIVERY_REQUEST/button/new' | translate }}</button>
    </div>
    <table mat-table #table [dataSource]="dataSource" matSort class="rounded-corners col-md-12">
      <ng-container matColumnDef="mdrID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERY_REQUEST/table/mdr' | translate }}<th>
          <td mat-cell *matCellDef="let element">{{ element.mdr.pickupID ? element.mdr.pickupID : (element.mdr.id | mdrId) }} </td>
      </ng-container>
      <ng-container matColumnDef="route">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERY_REQUEST/table/route' | translate }}<th>
          <td mat-cell *matCellDef="let element"> {{ element.pickupAddress }} <i class="fa fa-arrow-right pt-1 pl-1 mx-1"></i> {{ element.deliveryAddress }} </td>
      </ng-container>
      <ng-container matColumnDef="driver">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERY_REQUEST/table/driver' | translate }}<th>
          <td mat-cell *matCellDef="let element"> <div class="row"> <span class="col-md-4"  *ngFor="let d of element.driver">{{ d.name }} </span></div> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERY_REQUEST/table/status' | translate }}<th>
          <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="getRowColor(row.status)"
        (click)="goToMDR(row)"></tr>
    </table>
  </div>
</div>
