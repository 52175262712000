<mat-card *ngIf="show" class="p-0 py-1" [ngClass]="getStatusClass()">
  <mat-card-title class="m-0 pl-2 d-flex justify-content-between align-items-center">
    <a [href]="setRedirectUrl()">{{ dr.mdr ? (pickupID ? pickupID : (dr.idMDR | mdrId)) : 'sem MDR associada' }}</a>
    <div class="m-0 p-0 align-items-center">
      <i text class="fa fa-comments"
              matTooltip="há mensagens não lidas entre transportadora e embarcadora"
              matTooltipPosition="left"
              *ngIf="novasMensagens[6]">
      </i>
      <i text class="fa fa-comments"
              matTooltip="há mensagens não lidas entre transportadora e motorista"
              matTooltipPosition="left"
              *ngIf="novasMensagens[5]">
      </i>
      <i text class="fa fa-truck-monster"
              matTooltip="Necessita Op. Especiais"
              matTooltipPosition="left"
              *ngIf="dr.thirdPartInvoice">
      </i>
      <i text class="fa fa-street-view"
              matTooltip="Motorista no Site"
              matTooltipPosition="left"
              *ngIf="dr.arrivalTime && dr.status === 'InTransit'">
      </i>
      <i text class="fa fa-wifi"
              matTooltip="Caminhão visto na última hora"
              matTooltipPosition="left"
              *ngIf="!truckSaw && dr.status === 'InTransit'">
      </i>
      <i text class="fa"
              [matTooltip]="tooltip.join(', ')"
              matTooltipPosition="left"
              [ngClass]="icons[color]"
              *ngIf="icons[color]">
      </i>
    </div>
  </mat-card-title>
  <mat-card-content class="px-2 py-1">
    <div class="d-flex justify-content-center smaller-text">
      <div>{{dr.dateScheduling | date: 'dd/MM/yyyy - HH:mm' }}</div>
    </div>
    <div class="d-flex justify-content-center smaller-text">
      <div>{{ dr.mdr ? dr.mdr.shippingCompany : 'sem transportadora associada' }}</div>
    </div>
    <div class="d-flex justify-content-between smaller-text">
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.mdr ? (dr.mdr.type? dr.mdr.type: 'Sem Tipo') : 'Sem Tipo' }}</div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.prolog ? dr.prolog : 'Sem Prolog' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.client ? dr.client : 'sem cliente' }}</div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.site ? dr.site : 'sem site' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
        <div>{{( dr.pickupAddress ? dr.pickupAddress : 'Sem endereço' ) | address : '%c/%E' }}</div>
      </div>
      <i text class="fa fa-arrow-right"></i>
      <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
        <div>{{ dr.city ? dr.city : 'Sem cidade' }} {{ dr.uf ? dr.uf : 'Sem estado' }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
