<!-- parte superior -->
<div class="mx-5 my-4">
  <div style="float: right; position: relative;">
    <i class="fa fa-file-pdf click-button" matTooltip="Download em pdf" (click)="onClickPrint()"></i>
    <div *ngIf="role === 5"> <!-- LSP -->
      <i font class="fa fa-comment-alt click-button" (click)="goToChat(6)" matTooltip="{{ 'LOGISTIC-ORDER/chat_conveyor' | translate }}" aria-hidden="true"></i>
      <i font class="fa fa-comment-alt click-button" (click)="goToChat(5)" matTooltip="{{ 'LOGISTIC-ORDER/chat_driver' | translate }}" aria-hidden="true"></i>
    </div>
    <div *ngIf="role === 1 || role === 2 || role === 3"> <!-- ericsson -->
      <i font class="fa fa-comment-alt click-button" (click)="goToChat(6)" matTooltip="{{ 'LOGISTIC-ORDER/chat_shipper' | translate }}" aria-hidden="true"></i>
    </div>
  </div>
  <div>
    <p class="mat-h1">
      {{ 'LOGISTIC-ORDER/title/info-request' | translate }}: {{ mdr.pickupID ? mdr.pickupID : (mdr.id | mdrId)}}
    </p>
    <p>
      {{ 'LOGISTIC-ORDER/title/status' | translate }}:
      <span *ngIf="!editMDRStatus" class="order-status-yellow">{{mdr.status}}</span>
      <mat-form-field *ngIf="role !== 5 && editMDRStatus" class="col-md-3">
        <mat-select  placeholder="{{ 'CONTROL_TOWER/table/status' | translate }}" [(ngModel)]="mdr.status"  >
          <mat-option *ngFor="let elem of mdrStatus" [value]="elem.value"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <i *ngIf="role !== 5 && role !== 10 && !editMDRStatus" font class="fa fa-pencil-alt icon-header click-button" (click)="editStatus()" aria-hidden="true"></i>
      <i *ngIf="role !== 5 && role !== 10 && editMDRStatus" font class="fa fa-save icon-header click-button" (click)="saveStatus()" aria-hidden="true"></i>
    </p>
    <p>
      {{ 'LOGISTIC-ORDER/title/type' | translate }}:
      <span *ngIf="!editMDRType" class="order-status-yellow">{{mdr.type}}</span>
      <mat-form-field *ngIf="role !== 5 && editMDRType" class="col-md-3">
        <mat-select  placeholder="{{ 'CONTROL_TOWER/table/type' | translate }}" [(ngModel)]="mdr.type"  >
          <mat-option *ngFor="let elem of mdrType" [value]="elem.value"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <i *ngIf="role !== 5 && role !== 10 && !editMDRType && editMDRTypeAllowed(mdr.status)"
        font
        class="fa fa-pencil-alt icon-header click-button"
        (click)="editType()"
      ></i>
      <i *ngIf="role !== 5 && role !== 10 && editMDRType && editMDRTypeAllowed(mdr.status)"
        font
        class="fa fa-save icon-header click-button"
        (click)="saveType(mdr.type)"
      ></i>
    </p>
    <p>
      <app-sla type="mdr" [id]="mdr.id"></app-sla>
    </p>
    <p *ngIf="role === 1">
      <button mat-raised-button class='primary-button' (click)="createToken()">{{'WITHOUT_LOGIN/btn/create-token'|translate}}</button>
    </p>
    <p *ngIf="role !== 10">
      <button mat-raised-button (click)="openHistory()" class="blue-button"> {{ 'LOGISTIC-ORDER/history/' | translate }} </button>
    </p>
  </div>
</div>

<!-- barras de aviso -->
<div class="notification-bar-yellow row notification-bar-text" *ngIf="reservation!==''"   >
  <div class="col-md-12" >
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;font-size: 20px;font-weight: bold;">{{ 'LOGISTIC-ORDER/reservation' | translate }}</span>
  </div>
  <div class="col-md-12">
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;">{{reservation}}</span>
  </div>
</div>
<div class="notification-bar-yellow row notification-bar-text" *ngIf="valueNfStatus"   >
  <div class="col-md-12" >
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;font-size: 20px;font-weight: bold;">{{ 'LOGISTIC-ORDER/attention' | translate }}</span>
  </div>
  <div class="col-md-12">
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;">{{ 'LOGISTIC-ORDER/high_value' | translate }}</span>
  </div>
</div>
<div class="notification-bar-red row notification-bar-text" *ngIf="reprove!==''"   >
  <div class="col-md-12">
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;font-size: 20px;font-weight: bold;">{{ 'LOGISTIC-ORDER/reprove' | translate }} </span>
  </div>
  <div class="col-md-12">
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;">{{reprove}}</span>
  </div>
</div>
<div class="notification-bar-red row notification-bar-text" *ngIf="shipmentReturned!==''"   >
  <div class="col-md-12">
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;font-size: 20px;font-weight: bold;">{{ 'LOGISTIC-ORDER/shipmentReturned' | translate }}</span>
  </div>
  <div class="col-md-12">
    <span class="text" style="width: 80%;margin: 0 auto; text-align: center;word-break: break-all;">{{shipmentReturned}}</span>
  </div>
</div>

<!-- components -->
<app-geolocation [mdr]="mdr" *ngIf="showMap()"></app-geolocation>
<div style="padding-top: 20px; padding-bottom: 20px;" *ngIf="!showMap() && role !== 10">
  <button *ngIf="hiddenMap" mat-raised-button class='button green-button' (click)="mapButtonClick()">Veja aqui como foi sua viagem</button>
  <app-geolocation [mdr]="mdr" *ngIf="!hiddenMap"></app-geolocation>
</div>
<div id="printable" >
  <app-delivery-route [mdr]="mdr"></app-delivery-route>
  <app-transport #transport *ngIf="role !== 10"> [drivers]="drivers" ></app-transport>
  <!-- <app-tax></app-tax> -->
  <app-occurrence></app-occurrence>
  <app-attachments (updateValue)="updateNFCost()" [mdr]="mdr"></app-attachments>
</div>

<!-- botões inferiores -->
<div  class="d-flex justify-content-center pb-5">
  <div class="col-md-10 d-flex justify-content-start">
    <div class="pr-5">
      <div *ngIf="role !== 5 && role !== 10">
        <button  mat-raised-button *ngIf="mdr.status === 'MDR em criação'" class='button red-button'  (click)="deleteMDR()">{{'LOGISTIC-ORDER/title/cancel'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR devolvida por LSP'" class='button red-button'  (click)="deleteMDR()">{{'LOGISTIC-ORDER/title/cancel'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR em validação'" class='button red-button'  (click)="deleteMDR()">{{'LOGISTIC-ORDER/title/delete'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aguardando LSP'" class='button red-button'  (click)="reproveMDR()">{{'LOGISTIC-ORDER/title/reprove-mdr'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aprovada LSP'" class=' button red-button'  (click)="giveBackMDR()">{{'LOGISTIC-ORDER/title/back-mdr'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'Motorista e veiculo definidos'" class=' button red-button ' (click)="cancelTake()">{{'LOGISTIC-ORDER/title/withdrawal-canceled'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'Veículo carregado'" class=' button red-button ' (click)="allShipmentReturned()">{{'LOGISTIC-ORDER/title/charge-returned'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aprovada com ressalvas LSP'" class='button red-button'  (click)="reproveRessalva()">{{'LOGISTIC-ORDER/title/reprove'|translate}}</button>
      </div>
      <div *ngIf="role === 5">
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aguardando LSP'" class='button red-button'  (click)="reproveMDR()">{{'LOGISTIC-ORDER/title/reprove-mdr'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aprovada LSP'" class=' button red-button'  (click)="giveBackMDR()">{{'LOGISTIC-ORDER/title/back-mdr'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'Motorista e veiculo definidos'" class=' button red-button ' (click)="cancelTake()">{{'LOGISTIC-ORDER/title/withdrawal-canceled'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'Veículo carregado'" class=' button red-button ' (click)="allShipmentReturned()">{{'LOGISTIC-ORDER/title/charge-returned'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aprovada com ressalvas LSP'" class='button red-button'  (click)="reproveRessalva()">{{'LOGISTIC-ORDER/title/reprove'|translate}}</button>
      </div>
    </div>
    <div class="pr-5">
      <div *ngIf="role !== 5 && role !== 10">
        <button  mat-raised-button *ngIf="mdr.status === 'MDR em criação'" class='button yellow-button'  (click)="updateMDR()">{{'LOGISTIC-ORDER/title/save'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR devolvida por LSP'" class='button yellow-button'  (click)="updateMDR()">{{'LOGISTIC-ORDER/title/save'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR em validação'" class='button yellow-button'  (click)="updateMDR()">{{'LOGISTIC-ORDER/title/save'|translate}}</button>
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aguardando LSP'" class='button yellow-button'  (click)="approveWithConditionsMDR()">{{'LOGISTIC-ORDER/title/aprove-reservation'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR aprovada com ressalvas LSP'" mat-raised-button class='button green-button' (click)="aproveRessalva()">{{'LOGISTIC-ORDER/title/aprove'|translate}}</button>
      </div>
      <div *ngIf="role === 5">
        <button  mat-raised-button *ngIf="mdr.status === 'MDR aguardando LSP'" class='button yellow-button'  (click)="approveWithConditionsMDR()">{{'LOGISTIC-ORDER/title/aprove-reservation'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR aprovada com ressalvas LSP'" mat-raised-button class='button green-button' (click)="aproveRessalva()">{{'LOGISTIC-ORDER/title/aprove'|translate}}</button>
      </div>
    </div>
    <div class="pr-5">
      <div *ngIf="role !== 5 && role !== 10">
        <button  *ngIf="mdr.status === 'MDR em validação'" mat-raised-button class='button green-button' (click)="saveAddressInMapp()">{{'LOGISTIC-ORDER/title/validate-adresses'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR em criação'" mat-raised-button class='button green-button' (click)="sendLSP()">{{'LOGISTIC-ORDER/title/send-LSP'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR devolvida por LSP'" mat-raised-button class='button green-button' (click)="sendLSP()">{{'LOGISTIC-ORDER/title/send-LSP'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR aguardando LSP'" mat-raised-button class='button green-button' (click)="acceptMDR()">{{'LOGISTIC-ORDER/title/accept-order'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR aprovada LSP'" mat-raised-button class='button green-button'(click)="defineDriver()">{{'LOGISTIC-ORDER/title/send-driver'|translate}}</button>
        <button  *ngIf="mdr.status === 'Motorista e veiculo definidos'" mat-raised-button class='button green-button' (click)="vehicleLoaded()">{{'LOGISTIC-ORDER/title/vehicle-charged'|translate}}</button>
        <button  *ngIf="mdr.status === 'Veículo carregado'" mat-raised-button class='button green-button' (click)="delivered()">{{'LOGISTIC-ORDER/title/charge-successful'|translate}}</button>
        <button  *ngIf="mdr.status === 'Carga entregue com sucesso'" mat-raised-button class='button green-button' (click)="deliveredCertified()">{{'LOGISTIC-ORDER/title/charge-LSP'|translate}}</button>
        <button  *ngIf="mdr.status === 'Carga retornada total'" mat-raised-button class='button green-button' (click)="deliveredCertified()">{{'LOGISTIC-ORDER/title/charge-LSP'|translate}}</button>
        <button  *ngIf="mdr.status === 'Entrega de carga certificada pelo LSP'" mat-raised-button class='button green-button' (click)="pay()">{{'LOGISTIC-ORDER/title/send-pay'|translate}}</button>
      </div>
      <div *ngIf="role === 5">
        <button  *ngIf="mdr.status === 'MDR aguardando LSP'" mat-raised-button class='button green-button' (click)="acceptMDR()">{{'LOGISTIC-ORDER/title/accept-order'|translate}}</button>
        <button  *ngIf="mdr.status === 'MDR aprovada LSP'" mat-raised-button class='button green-button'(click)="defineDriver()">{{'LOGISTIC-ORDER/title/send-driver'|translate}}</button>
        <button  *ngIf="mdr.status === 'Motorista e veiculo definidos'" mat-raised-button class='button green-button' (click)="vehicleLoaded()">{{'LOGISTIC-ORDER/title/vehicle-charged'|translate}}</button>
        <button  *ngIf="mdr.status === 'Veículo carregado'" mat-raised-button class='button green-button' (click)="delivered()">{{'LOGISTIC-ORDER/title/charge-successful'|translate}}</button>
        <button  *ngIf="mdr.status === 'Carga entregue com sucesso'" mat-raised-button class='button green-button' (click)="deliveredCertified()">{{'LOGISTIC-ORDER/title/charge-LSP'|translate}}</button>
        <button  *ngIf="mdr.status === 'Carga retornada total'" mat-raised-button class='button green-button' (click)="deliveredCertified()">{{'LOGISTIC-ORDER/title/charge-LSP'|translate}}</button>

      </div>
    </div>
  </div>
</div>
