import {Component, OnInit, ViewChild, Inject, ElementRef, AfterViewInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';
import {ClientUserService} from '../../providers/client-user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatChipInputEvent} from '@angular/material';
import {HubService} from '../../providers/hub.service';
import {HUB} from '../../models/hub';
import {HUBDR} from '../../models/hub__dr';

declare var google: any;
import { } from 'googlemaps';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})

export class HubComponent implements AfterViewInit {
  public searchControl: FormControl;
  inputOVs: number;
  selection = new SelectionModel<HUB>(true, []);
  opened: any;
  data: HUB[];
  lat: any;
  lng: any;
  marker = false;
  displayedColumns = ['address', 'name', 'shippingCompany', 'latitude', 'longitude'];
  hubColumns = ['dr', 'value', 'since'];
  dataSource = new MatTableDataSource<HUB>(this.data);
  hubSource = new MatTableDataSource<any>();
  totalHub = 0;

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;
  public searchChips: any[] = [];
  removable = true;
  selectable = true;
  selectedHub: HUB = undefined;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  searchTerm = '';
  tokenSeparator = '@@@@@@@@@';

  @ViewChild('gmap', {static: false}) gmapElement: any;
  map: google.maps.Map;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader, public clientService: ClientUserService,
              private drService: DrService, private hubService: HubService,
              public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
    this.dataSource.sort = this.sort;
    this.hubService.getAll().subscribe( (response: HUB[]) => {
      this.data = response;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data: any, mergedFilter: string ) => {
        const filter = this.splitSearchTerms(mergedFilter);
        let display = false;
        this.displayedColumns.forEach( (el, i, arr) => {
          // atualmente é feita a comparação com OR lógico
          // para mudar para AND lógico: trocar .some() por .every()
          if ( filter.length === 0 ) {
            display = true;
          } else if (filter.some( chip => {
              if (el in data &&
                data[el] !== null) {
                if (typeof data[el] === 'string' &&
                  data[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                  return true;
                } else if (typeof data[el] === 'number' &&
                  data[el] === parseInt(chip, 10)) {
                  return true;
                }
              }
              return false;
            })) {
            display = true;
          }
        });
        return display;
      };
    });
   }

  ngAfterViewInit() {
  }

  getTowerControl() {
    this.drService.getTower().subscribe( (elem) => {
      // console.log(elem);
    });
  }

  goToHub(row) {
    this.hubService.getFromHub(row.id).subscribe( (data) => {
      this.selectedHub = row;
     // console.log(data);
      this.hubSource =  new MatTableDataSource<any>(data);
      this.totalHub = 0;
      data.forEach((el) => {
        this.totalHub += parseInt(el.dr.value, 10);
      });
    });
  }

  addHub() {
    this.router.navigate(['add-hub/']);
  }

  editHub() {
   // console.log(this.selectedHub);
    this.router.navigate(['add-hub/' + this.selectedHub.id]);
  }

  removeHub() {
    // console.log(this.selectedHub);
    this.hubService.removeHub(this.selectedHub.id).subscribe((data) => {
      this.data = this.data.filter(e => (e.id !== this.selectedHub.id));
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.selectedHub = undefined;
    }, (error) => {
     // console.log(error);
    });
  }

  getMapClass() {
    return  this.marker ? 'map-show' : 'map-hidden';
  }

  viewMap() {
   // console.log(this.marker);
    if (this.marker === true ) {
      this.marker = false;
    } else {
     // console.log(this.lat);
      if (this.lat === undefined || this.lng === undefined ) {
        this.convertAddressToLatLng();
        this.marker = true;
      } else {
        this.marker = true;
      }
    }
  }

  convertAddressToLatLng() {
    // const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': this.selectedHub.address}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.lat = lat;
        this.lng = lng;
        this.marker = true;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
       // console.log(status);
      }
    });
  }

  goToMDR(k) {
    this.router.navigate(['logistic-order/' + k.dr.idMDR]);
  }

  applyFilter() {
    this.dataSource.filter = this.mergeSearchTerms(this.searchChips);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchChips.push({'name': value.trim()});
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }

  remove(chip): void {
    const index = this.searchChips.indexOf(chip);
    if (index >= 0) {
      this.searchChips.splice(index, 1);
    }
    this.applyFilter();
  }

  mergeSearchTerms(terms): string {
    let merged = '';
    terms.forEach( (el, i, arr) => {
      if (typeof el.name === 'string') {
        merged = merged + this.tokenSeparator + '"' + el.name + '"';
      } else if (typeof el.name === 'number') {
        merged = merged + this.tokenSeparator + String(el.name);
      }
    });
    merged = merged.substring(this.tokenSeparator.length, merged.length);
    return merged;
  }

  splitSearchTerms(terms) {
    const split = [];
    terms.split(this.tokenSeparator).forEach( (el, i, arr) => {
      if (el[0] === '"' && el[el.length - 1] === '"') {
        split.push(el.substring(1, el.length - 1));
      } else {
        split.push(parseInt(el, 10));
      }
    });
    return split;
  }

  return(): void {
    this.selectedHub = undefined;
  }
}
