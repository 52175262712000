<div fxLayout="row" fxLayout.xs="column" fxFlexFill>
    <div fxFlex="50">
        <mat-card class="mx-4 my-4 card-height">
            <mat-card-header>
                {{ "DRS/tracked" | translate }}
            </mat-card-header>
            <mat-card-content class="circle-height">
                <circle-progress [percent]="100" [responsive]="true" [subtitle]="'DRS'" [subtitleFontSize]="20"
                    [title]="presentData">
                </circle-progress>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex="50">
        <mat-card class=" mx-4 my-4 card-height">
            <mat-card-header>
                {{ "DRS/not-tracked" | translate }}
            </mat-card-header>
            <mat-card-content class="circle-height">
                <circle-progress [percent]="100" [responsive]="true" [subtitle]="'DRS'" [subtitleFontSize]="20"
                    [title]="pastData"></circle-progress>
            </mat-card-content>
        </mat-card>
    </div>
</div>