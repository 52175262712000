import {Component, OnInit, Input, ViewChild, Inject} from '@angular/core';
import {DR} from '../../../models/dr';
import {MatTableDataSource, MatSort, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatPaginator} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {DrService} from '../../../providers/dr.service';
import {DrObservable} from '../../../services/dr.service';
import {TranslateService} from '@ngx-translate/core';
import {Occurrence} from '../../../models/occurrence';
import {HUB} from '../../../models/hub';
import {HubService} from '../../../providers/hub.service';
import {HUBDR} from '../../../models/hub__dr';
import {OVService} from '../../../providers/ov.service';
import {OV} from '../../../models/ov';
import { MDR } from '../../../models/mdr';
import { ESTRUTURA } from '../../../providers/site.service';
import {ClientUserService} from '../../../providers/client-user.service';

// table with expandable rows:
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-delivery-route',
  templateUrl: './delivery-route.component.html',
  styleUrls: ['./delivery-route.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeliveryRouteComponent implements OnInit {
  data: DR[] = [];
  @Input() mdr: MDR;
  dataSource = new MatTableDataSource<DR>(this.data);
  displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'client', 'spec-ops', 'obs', 'view'];
  columnsToDisplay = ['deliveryAddress', 'site', 'status', 'dateScheduling', 'client'];

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  currentUser: any;
  role: any;
  siteNomes = {
    'form_6_1': 'Grua',
    'form_6_2': 'Correia',
    'form_6_3': 'Veículos Esp.',
    'form_6_4': 'Operação Manual',
    'form_6_5': 'Guindates'
  };

  constructor(public aRoute: ActivatedRoute,
              private drService: DrService,
              public hubService: HubService,
              public snackBar: MatSnackBar,
              public drObservable: DrObservable,
              public dialog: MatDialog,
              public ovService: OVService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    if (this.role !== 5) { // pra role!==5 adiciona botoes de editar e visualizar
      this.displayedColumns = [].concat(this.displayedColumns, ['action', 'view']);
    }
  }

  ngOnInit() {
    this.drObservable.currentDR.subscribe(dr => {
      this.data = dr;
      // console.log(this.data);
      for (let i = 0; i < this.data.length; i++) { // TODO optimize this n3 disgusting function
        const ovAux: OV[] = [];
        let str = '';
        if (this.data[i].ovList !== undefined) {
          for (let j = 0; j < this.data[i].ovList.length; j++) {
            let flag = 0;
            for (let k = 0; k < ovAux.length; k++) {
              if (ovAux[k].salesDocument === this.data[i].ovList[j].salesDocument) {
                flag = 1;
                break;
              }
            }
            if (flag === 0) {
              ovAux.push(this.data[i].ovList[j]);
              if (j !== 0) {
                str += ', ';
              }
              str += this.data[i].ovList[j].salesDocument;
            }
          }
          this.data[i].ovList = ovAux;
          this.data[i].obs = str;
        }

        // detalhes do site.form_6_?
        this.data[i]['site_form6'] = [];
        if ( this.data[i]['site_form'] ) {
          const keys = Object.keys(this.data[i]['site_form']).filter(e => e.indexOf('form_6_') === 0).filter(e => e.indexOf('_obs') === -1);
          for (let j = 0, len = keys.length ; j < len ; j++) {
            // console.log(this.data[i]['site_form'][keys[j]]);
            if (this.data[i]['site_form'][keys[j]] === ESTRUTURA.EX) {
              this.data[i]['site_form6'].push(this.siteNomes[keys[j]]);
            }
          }
        }
      }
      this.dataSource = new MatTableDataSource<DR>(this.data);
    });
  }

  setUserArrivalTime(dr: DR) {
    dr.userArrivalTime = new Date();

    this.drService.update(dr).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
      } else {
        dr.userArrivalTime = null;
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  openEditDialog(dr: DR): void {
    const dialogRef = this.dialog.open(EditDrDialogComponent, {
      data: {
        role: this.role,
        dr: dr,
      }
    });

    dialogRef.afterClosed().subscribe( (response) => {
    });
  }

  openViewDialog(dr: DR): void {

    const dialogRef = this.dialog.open(ViewInfoComponent, {
      width: '600px',
      data: {
        dr: dr,
        role: this.role
      },
    });

    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
    });
  }

  openHistoryDialog(dr: DR): void {
    this.drService.getHistory(dr.id).subscribe((history) => {
      const dialogRef = this.dialog.open(HistoryDialogComponent, {
        width: '80vw',
        maxHeight: '80vh',
        data: {history: history}
      });

    });

  }

}

@Component({
  selector: 'app-edit-dr-dialog',
  templateUrl: 'edit-dr-dialog.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class EditDrDialogComponent implements OnInit {
  current: DR;
  hubList: HUB[] = [];
  hubId: any;
  hubObs: any;
  clientList = [];
  statusList = ['InTransit',
                'READY_TO_PICKUP',
                'Replan - Retido',
                'Replan - Sefaz',
                'Replan - Pernoite',
                'Replan - Antecipação',
                'Replan - Postergação',
                'HubReturn',
                'Canceled',
                'Delivered'];

  constructor(
    public dialogRef: MatDialogRef<EditDrDialogComponent>,
    public hubService: HubService,
    @Inject(MAT_DIALOG_DATA) public data,
    public translate: TranslateService,
    public drService: DrService,
    public clientService: ClientUserService,
    public snackBar: MatSnackBar
  ) {
    this.hubService.getAll().subscribe( (hubs) => {
      this.hubList = hubs;
    });
    this.clientService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
  }

  ngOnInit() {
    // faz uma copia da DR para edição
    this.current = Object.assign({}, this.data.dr);
  }

  onYesClick() {
    // compara DR copiada para edição com DR anterior
    const checkChanges = (key) => {
      return ( this.current[key] !== this.data.dr[key] );
    };

    // se nao houve alguma modificação
    const keys = Object.keys(this.current);
    if ( !keys.some(checkChanges) ) {
      this.snackBar.open('Nenhum valor foi alterado', 'Ok', {duration: 2000});
      this.dialogRef.close();
      return;
    }

    if ( this.current.status === 'HubReturn') {
      const newHUBDR = new HUBDR();
      newHUBDR.hubID = this.hubId;
      newHUBDR.drID = this.data.dr.id;
      newHUBDR.when = new Date();
      newHUBDR.observation = this.hubObs;
      this.hubService.createHubDr(newHUBDR).subscribe( (elem) => {
        // console.log(elem);
      });
    }

    this.drService.update(this.current).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
        // copia valores pra DR anterior
        keys.forEach((key) => {
          this.data.dr[key] = this.current[key];
        });
        this.dialogRef.close();
      } else {
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-view-info',
  templateUrl: 'view-info.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class ViewInfoComponent implements OnInit {
  select: any;
  dateCollect: Date;
  hour: any;
  previous: any;
  hubId: any;
  hubObs: any;
  role: number;
  dataSource = new MatTableDataSource<any>(this.data);
  displayedColumns2 = ['salesDocument', 'salesOrderQuantity', 'materialCode', 'customerName'];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  HUs: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditDrDialogComponent>, public hubService: HubService,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public drService: DrService, public snackBar: MatSnackBar) {
    // console.log("Received Data");
    // console.log(data);
    this.role = data.role;
  }

  ngOnInit() {
    // console.log(this.data.id);
    this.drService.getAllInfo(this.data.dr.id).subscribe(elem => {
      // console.log(elem);
      this.dataSource = new MatTableDataSource<any>(elem);
      setTimeout(() => this.dataSource.paginator = this.paginator);

      // remove HUs repetidas
      const unique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      let HUs = [];
      for (let i = 0, len = elem.length; i < len; i++) {
        HUs = HUs.concat(elem[i]['HUs']);
      }
      this.HUs = HUs.filter(unique);
    });
  }

  onYesClick() {
    if ( this.previous.select === this.select
      && this.previous.dateCollect === this.dateCollect
      && this.previous.hour === this.hour
      && this.previous.deliveryAddress === this.data.dr.deliveryAddress) {
      this.snackBar.open('Nenhum valor foi alterado', 'Ok', {duration: 2000});
      this.dialogRef.close();
      return;
    }

    if ( this.select === 'HubReturn') {
      const newHUBDR = new HUBDR();
      newHUBDR.hubID = this.hubId;
      newHUBDR.drID = this.data.dr.id;
      newHUBDR.when = new Date();
      newHUBDR.observation = this.hubObs;
      this.hubService.createHubDr(newHUBDR).subscribe( (elem) => {
        // console.log(elem);
      });
    }

    // if (this.previous === 'HubReturn') {
    //
    // }

    this.data.dr.status = this.select;
    if (this.hour !== undefined && this.dateCollect !== undefined) {
      // console.log('cheguei!');
      const time = this.hour.split(':');
      this.data.dr.dateScheduling = new Date(this.dateCollect.setHours(parseInt(time[0], 0), parseInt(time[1], 0)));
    }
    // console.log(this.data);
    this.drService.update(this.data.dr).subscribe( (el) => {
      if ( el === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
        this.dialogRef.close();
      } else {
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-history',
  templateUrl: 'history.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class HistoryDialogComponent implements OnInit {
  history = [];
  columns = [
    {value: 'deliveryAddress', name: 'DELIVERY_ROUTE/address'},
    {value: 'status', name: 'DELIVERY_ROUTE/status'},
    {value: 'quem', name: 'DELIVERY_ROUTE/by'},
    {value: 'quando', name: 'DELIVERY_ROUTE/on'},
    {value: 'dateScheduling', name: 'DELIVERY_ROUTE/date'}
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public datepipe: DatePipe) {
    this.history = data.history;
    this.history.forEach(day => {
      day.dateScheduling = new Date(day.dateScheduling);
      day.dateScheduling = this.datepipe.transform(day.dateScheduling, 'dd/MM/yyyy hh:mm:ss');
      day.quando = new Date(day.quando);
      day.quando = this.datepipe.transform(day.quando, 'dd/MM/yyyy hh:mm:ss');
    });
  }
  ngOnInit() {

  }
}
