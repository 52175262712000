import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-drs-indice',
    templateUrl: './drs-indice.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class DrsIndiceComponent implements OnInit {
    presentData: number;
    pastData: number;

    ngOnInit() { }

    createIndice(data: any) {
        this.presentData = data.filter(element => element.last_driver_position === 'present')[0].count;
        this.pastData = data.filter(element => element.last_driver_position === 'missing')[0].count;
    }

}
