import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import * as Highcharts from 'highcharts';
import * as Exporting from 'highcharts/modules/exporting';
import { ChartModule, Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pods-chart',
    templateUrl: './pods-chart.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class PodsChartComponent implements OnInit {
    chart: Chart;
    noData = false;
    constructor(public translate: TranslateService) { }


    ngOnInit() {
    }

    createChart(data: any) {
        if (data.length > 0) {
            this.noData = false;
            this.chart = new Chart({
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                        }
                    }
                },
                xAxis: {
                    categories: data.map(day => day.createdAt),
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: this.translate.instant('PODS/counting'),
                    type: undefined,
                    data: data.map(day => day.count)
                }]
            });
        } else {
            this.noData = true;
        }
    }

}
