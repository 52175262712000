<div class="mx-5 my-4">
    <p class="mat-h1">{{ 'DASHBOARD/title' | translate }}</p>
</div>
<div class="mx-4 my-4 white-background back-container">
    <mat-card>
        <mat-card-title>
                {{"COST_PER_KG" | translate}}
        </mat-card-title>
        <mat-card-content>
            <app-cost-per-kilo-chart #ckChart></app-cost-per-kilo-chart>
        </mat-card-content>
    </mat-card>
</div>
<div class="mx-4 my-4 white-background back-container">
    <mat-card>
        <mat-card-title>
            {{ 'OCURRENCE/title' | translate}}
        </mat-card-title>
        <mat-card-content>
            <app-occurrence-chart #occurrenceChart></app-occurrence-chart>
        </mat-card-content>
    </mat-card>
</div>
<div class="mx-4 my-4 white-background back-container">
    <mat-card>
        <mat-card-title>
            {{ 'Pods' | translate}}
        </mat-card-title>
        <mat-card-content>
            <app-pods-chart #podsChartComponent></app-pods-chart>
        </mat-card-content>
    </mat-card>
</div>
<div class="mx-4 my-4 white-background back-container">
    <mat-card>
        <mat-card-title>
            {{ 'MDR/status' | translate}}
        </mat-card-title>
        <mat-card-content>
            <app-mdr-chart #mdrChartComponent></app-mdr-chart>
        </mat-card-content>
    </mat-card>
</div>

<div class="bounds">
    
    <div class="content" 
         fxLayout="row" 
         fxLayout.xs="column" 
         fxFlexFill >
         
        <div fxFlex="40" class="indice-height" fxFlex.xs="55">
            <mat-card class=" mx-4 my-4 card-height">
                <mat-card-title>
                    {{ 'MDR/company' | translate}}
                </mat-card-title>
                <mat-card-content>
                    <app-mdr-pie-chart #mdrPieChartComponent></app-mdr-pie-chart>
                </mat-card-content>
            </mat-card>
        </div>

        <app-drs-indice #drsIndiceComponent class="indice-height" fxFlex="60" fxFlex.xs="55"></app-drs-indice>
        
    </div>
    
  </div>

<div class=" my-4  mx-4 white-background back-container">
    <mat-card>
        <mat-card-title>
            {{ 'DR/status' | translate}}
        </mat-card-title>
        <mat-card-content>
            <app-drs-chart #drsChartComponent></app-drs-chart>
        </mat-card-content>
    </mat-card>
</div>