import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { MdrService } from '../../../providers/mdr.service';

@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss']
})
export class SlaComponent implements OnInit {
  @Input() id: number;
  @Input() type: string;
  public isSLA = false;
  public editMDRSla = false;
  public role = null;
  public newSla = false;
  public currentUser = null;
  mdrSla = [
    {name: 'Yes', value: true},
    {name: 'No', value: false},
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mdrService: MdrService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
  }

  ngOnInit() {
    const id$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.mdrService.isSLA(parseInt(params.get('id'), 10)))
    );
    id$.subscribe((response: any) => {
      this.isSLA = response;
    });
  }

  editSla() {
    this.editMDRSla = !this.editMDRSla;
  }
  saveSla() {
    this.editMDRSla = !this.editMDRSla;
    if (this.newSla) {
      this.mdrService.setSLA(this.id).subscribe((response) => {
        this.isSLA = true;
      });
    } else {
      this.mdrService.unsetSLA(this.id).subscribe((response) => {
        this.isSLA = false;
      });
    }
  }
}
