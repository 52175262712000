import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {Action, Event} from '../../models/events';
import {User} from '../../models/user';
import {Message} from '../../models/message';
import {SocketService} from '../../providers/socket.service';
import {MatTableDataSource} from '@angular/material';
import {DR} from '../../models/dr';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatService} from '../../providers/chat.service';
import {NotificationService} from '../../providers/notification.service';
import {MdrService} from '../../providers/mdr.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {
  @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
  opened: any;
  public action = Action;
  public user: User;
  public messages: Message[] = [];
  public messageContent: string;
  public ioConnection: any;
  public channel: number;
  currentUser: any;
  users: string[] = [];
  pickupID: any = null;
  public chatType: number;

  constructor(private socketService: SocketService, public router: Router, public aRoute: ActivatedRoute,
              public mdrService: MdrService,
              public chat: ChatService, public notificationService: NotificationService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.aRoute.params.subscribe( (params) => {
      this.channel = params.id;
      this.chatType = params.type;
      this.mdrService.getPickupID(this.channel).subscribe( (pid) => {
        this.pickupID = pid;
      });
      this.initIoConnection(this.channel);
    });
  }

  private getUsers(): void {
    this.users.push(this.currentUser.user.name);
    this.messages.forEach( (el, i, arr) => {
      if ( this.users.indexOf(el.user.name) === -1 ) {
        this.users.push(el.user.name);
      }
    });
  }

  private initIoConnection(id: number): void {
    this.socketService.initSocket('chat/' + id + '/' + this.chatType);
    this.user = this.currentUser.user;
    this.chat.getByChannel(id, this.chatType).subscribe( (data) => {
      data.forEach( (el: any) => {
        const k = new Message({id: el.userId, name: el.userName}, el.message, this.channel, el.createdAt, this.chatType);
        this.messages.push(k);
      });
      this.getUsers();
      this.scrollToBottom();
    });
    // this.socketService.joinConversation(id);

    this.ioConnection = this.socketService.onMessage()
      .subscribe((message: Message) => {
        // console.log(message);
        this.messages.push(message);
      });

    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        // console.log('connected');
      });

    this.socketService.onEvent(Event.NEW_MESSAGE)
        .subscribe((message) => {
          this.scrollToBottom();
        // this.messages.push(message);
    });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        // console.log('disconnected');
      });
  }

  public scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }, 500);
    } catch (err) {
     // console.log(err);
    }
  }

  public sendMessage(message: string): void {
    this.scrollToBottom();
    if (!message) {
      return;
    }
    this.socketService.send(new Message(this.user, message, this.channel, new Date(), this.chatType));
    this.messageContent = null;
    this.notificationService.createMessage(this.channel, this.currentUser.user.id).subscribe( (data) => {
      this.notification(data);
    });

  }
  notification(data) {
    this.socketService.initSocketNotification(Number(this.user));
    const message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
    this.socketService.sendNotification(message);
   // console.log(data);
    data.forEach(value => {
      const nmessage = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), this.chatType);
      this.socketService.sendNotification(nmessage);
    });

  }

  public sendNotification(params: any, action: Action): void {
    let message: Message;

    if (action === Action.JOINED) {
      message = new Message(this.user, action, this.channel, new Date(), this.chatType);
    } else if (action === Action.RENAME) {
      // console.log('Nada a fazer');
    }

    this.socketService.send(message);
  }

}
