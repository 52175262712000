import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { ConfigurationService } from '../../providers/configuration.service';

@Component({
  selector: 'app-menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  currentUser: any;
  role: number;
  lastUpdate: Date;
  show = false;
  menuItems = [{ name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }];

  constructor(public auth: AuthService, public router: Router, public drService: DrService, public configuration: ConfigurationService) {
    this.configuration.getConfiguration('menu').subscribe( (el) => {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.role = this.currentUser.user.role;
      // console.log(this.role);
      this.menuItems = el[this.role];
    });
  }

  ngOnInit() {
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['login']);
  }
}
