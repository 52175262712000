<h1 mat-dialog-title>Alterar Status da DR</h1>
<div mat-dialog-content>
  <form #form="ngForm" class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
      <input matInput placeholder="Endereço" name="text" [(ngModel)]="current.deliveryAddress" required>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-select [(ngModel)]="current.status" placeholder="Selecione o Status" name="status" required>
        <mat-option *ngFor="let status of statusList" [value]="status">
          {{ status | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="current.status === 'HubReturn'" class="col-md-6">
      <mat-select  placeholder="HUB" name="HubId" [(ngModel)]="hubId" required>
        <mat-option *ngFor="let elem of hubList" [value]="elem.id"  >
          {{ elem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="current.status === 'HubReturn'"  class="col-md-6">
      <input matInput placeholder="Observação" name="obs" [(ngModel)]="hubObs" required>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput required
        placeholder="Data de entrega da DR"
        name="datecollect"
        [(ngModel)]="current.dateScheduling"
        [owlDateTimeTrigger]="date"
        [owlDateTime]="date">
      <owl-date-time #date pickerType="both"></owl-date-time>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput
        [disabled]="data.role !== 1"
        [owlDateTimeTrigger]="dt"
        [owlDateTime]="dt"
        name="userArrivalTime"
        placeholder="Horário de chegada informado pela LSP"
        [(ngModel)]="current.userArrivalTime">
      <owl-date-time #dt></owl-date-time>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="Cidade" [(ngModel)]="current.city" name="city" required>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="UF" [(ngModel)]="current.uf" name="uf" required>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="Infra" [(ngModel)]="current.infraType" name="infra">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <mat-label>{{'NEW-DELIVERY-REQUEST/text/client' | translate}}</mat-label>
      <mat-select [(ngModel)]="current.client" name="client" required>
        <mat-option *ngFor="let client of clientList" [value]="client">
          {{client}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/site' | translate }}" name="site" [(ngModel)]="current.site">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/receiverPhone' | translate }}" name="receiverPhone" [(ngModel)]="current.receiverPhone">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/receiverName' | translate }}" name="receiverName" [(ngModel)]="current.receiverName">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/supervisorPhone' | translate }}" name="supervisorPhone" [(ngModel)]="current.supervisorPhone">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/supervisorName' | translate }}" name="supervisorName" [(ngModel)]="current.supervisorName">
    </mat-form-field>
    <mat-checkbox class='col-md-6' [(ngModel)]="current.thirdPartInvoice" name="thirdPartInvoice">
      {{ 'EDIT_DR_DIALOG/thirdPartyInvoice' | translate }}
    </mat-checkbox>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/value' | translate }}" name="value" [(ngModel)]="current.value" disabled>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/volume' | translate }}" name="volume" [(ngModel)]="current.volume" disabled>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/weight' | translate }}" name="weight" [(ngModel)]="current.weight" disabled>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/maxLength' | translate }}" name="maxLength" [(ngModel)]="current.maxLength" disabled>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/unificationID' | translate }}" name="unificationID" [(ngModel)]="current.unificationId">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="{{ 'EDIT_DR_DIALOG/siteTeam' | translate }}" name="siteTeam" [(ngModel)]="current.siteTeam">
    </mat-form-field>
    <mat-form-field class='col-md-12'>
      <textarea placeholder="Prolog" matInput [(ngModel)]="current.prolog" name="prolog" required></textarea>
    </mat-form-field>
  </form>
</div>
<br>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
  <button mat-button (click)="onYesClick()" [disabled]="!form.valid">Sim</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
