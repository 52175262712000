import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {DrService} from '../../providers/dr.service';
import {MapsAPILoader} from '@agm/core';
import {Router} from '@angular/router';
import {MdrService} from '../../providers/mdr.service';
import {MDR} from '../../models/mdr';

@Component({
  selector: 'app-mdr-management',
  templateUrl: './mdr-management.html',
  styleUrls: ['./mdr-management.scss']
})
export class MdrManagementComponent implements OnInit {
  columns = [
    {value: 'observation', name: 'MDR_MANAGEMENT/table/observation'},
    {value: 'status', name: 'MDR_MANAGEMENT/table/status'},
    {value: 'createBy', name: 'MDR_MANAGEMENT/table/userName'},
    {value: 'createdAt', name: 'MDR_MANAGEMENT/table/date'}
  ];
  data: any[] = [];

  constructor(public dialog: MatDialog,
              public mdrService: MdrService,
              public router: Router) {
  }

  ngOnInit() {
    this.mdrService.getAllMDR().subscribe( (data: MDR[]) => {
      this.data = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === 'MDR em validação' || data[i].status === 'MDR em criação') {
          data[i].observation = (data[i].pickupID ? String(data[i].pickupID) : ('AW' + ('0000000' + data[i].id).slice(-6)) );
          this.data.push(data[i]);
        }
      }
    });
  }

  goTo(row, that) {
    that.router.navigate(['logistic-order', row.id]);
  }
}
