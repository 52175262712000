import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-drs-chart',
    templateUrl: './drs-chart.component.html',
    styleUrls: ['../dashboard.component.scss']
})

export class DrsChartComponent implements OnInit {
    chart: Chart;
    noData = false;
    translations = {
        title: '',
        seriesName: []
    };
    constructor(public translate: TranslateService) {
    }


    ngOnInit() { }

  createChart(data: any) {
    // console.log(data);
        if (data.length > 0) {
            this.noData = false;
            const series = data.map(status => {
                return { name: status.status, type: undefined, data: [status.count] };
            });
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                xAxis: {
                  type: 'category',
                  labels: {
                    format: 'Status'
                  }
                },

              tooltip: {
                formatter: function() {
                  return '<b>' +  this.series.name + ':</b> ' + this.point.y ;
                }
                },
                title: {
                  text: ''
                },
                credits: {
                  enabled: false
                },
                series: series
              });
            } else {
              this.noData = true;
            }
        }
  }
