import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsxService {
  constructor() { }

  public export(json: any[], excelFileName: string): void {
    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // this.save(excelBuffer, excelFileName);
  }

  private save(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    // adiciona extensao
    if ( fileName.lastIndexOf(EXCEL_EXTENSION) !== (fileName.length - EXCEL_EXTENSION.length) ) {
      fileName = fileName + EXCEL_EXTENSION;
    }
    // adiciona timestamp
    if ( fileName.indexOf('%') !== -1 ) {
      fileName = fileName.replace('%', String(new Date().getTime()));
    }
    // FileSaver.saveAs(data, fileName);
  }

  public import(input): Observable<any> {
    // let workbook;
    // let excelInJSON;
    const fileReader = new FileReader();
    // init read
    fileReader.readAsArrayBuffer((<any>input.target).files[0]);
    // return new Observable((observer) => {
    //   // if success
    //   fileReader.onload = ev => {
    //     let binary = '';
    //     const bytes = new Uint8Array((<any>ev.target).result);
    //     const length = bytes.byteLength;
    //     for (let i = 0; i < length; i++) {
    //       binary += String.fromCharCode(bytes[i]);
    //     }
    //     // Converts the excel data in to json
    //     workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
    //     // only first sheet
    //     excelInJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    //     observer.next(excelInJSON);
    //   };
    //   // if failed
    //   fileReader.onerror = error => observer.error(error);
    // });
    return;
  }

}
