import {Component, OnInit, ViewChild, Inject, ElementRef, AfterViewInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {ActivatedRoute, Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';
import {ClientUserService} from '../../providers/client-user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatChipInputEvent} from '@angular/material';
import {HubService} from '../../providers/hub.service';
import {HUB} from '../../models/hub';
import {HUBDR} from '../../models/hub__dr';
import {Transportadora} from '../logistic-order/transport/transport.component';
import { SiteService } from '../../providers/site.service';

import { } from 'googlemaps';
declare var google: any;

@Component({
  selector: 'app-add-hub',
  templateUrl: './add-hub.component.html',
  styleUrls: ['./add-hub.component.scss']
})

export class AddHubComponent implements OnInit {
  inputOVs: number;
  opened: any;
  hub: HUB = new HUB();
  lat: number;
  lng: number;
  currentUser: any;
  role: any;
  user: any;
  transportadora: any[] = [];

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;

  @ViewChild('gmap', {static: false}) gmapElement: any;
  map: google.maps.Map;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader, public clientService: ClientUserService,
              private drService: DrService, private hubService: HubService, public aRoute: ActivatedRoute,
              public siteService: SiteService,
              public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.user = this.currentUser.user.id;
    this.siteService.getShippingCompanys().subscribe((data) => {
      this.transportadora = data;
    });
  }

  ngOnInit() {
    this.aRoute.params.subscribe( (params) => {
      if (params.id !== undefined) {
        this.hubService.getHub(params.id).subscribe( (h) => {
          this.hub = h;
        });
      }
    });

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  createHub() {
    this.hub.address = (document.getElementById('hubAddress') as HTMLInputElement).value;
    this.hubService.create(this.hub).subscribe( (el) => {
     // console.log(el);
      if (el !== null) {
        this.router.navigate(['hub/']);
      } else {
        this.snackBar.open('Não foi possível salvar o HUB', 'Ok', {duration: 3000});
      }
    });
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('hubAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.hub.lat = lat;
        this.hub.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
       // console.log(status);
      }
    });
  }

  convertLatLngToAddress() {
    if (this.hub.lat === null || this.hub.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.hub.address = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
       // console.log(status);
      }
    });
  }

  goToMDR(k) {
    this.router.navigate(['logistic-order/' + k.dr.idMDR]);
  }
}
