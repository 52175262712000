import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {DrService} from '../../providers/dr.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-delivery-order',
  templateUrl: './delivery-order.component.html',
  styleUrls: ['./delivery-order.component.scss']
})

export class DeliveryOrderComponent implements OnInit {
  mdr: MDR[] = [];
  columns = [
    {value: 'observation', name: 'DELIVERY_ORDER/table/observation'},
    {value: 'status', name: 'DELIVERY_ORDER/table/status'},
    {value: 'client', name: 'DELIVERY_ORDER/table/client'},
    {value: 'shippingCompany', name: 'DELIVERY_ORDER/table/shippingCompany'},
  ];

  constructor(public mdrService: MdrService,
              public router: Router) {
  }

  ngOnInit() {
    this.mdrService.getAllWithClient().subscribe( (data: any[]) => {
      this.mdr = [];
      for (let i = 0; i < data.length; i++) {
        data[i].observation = (data[i].pickupID ? String(data[i].pickupID) : ('AW' + ('0000000' + data[i].id).slice(-6)) );
        data[i].client = (data[i].drList[0].client ? data[i].drList[0].client : 'SEM CLIENTE');
        this.mdr.push(data[i]);
      }
    });
  }

  goTo(row, that) {
    that.router.navigate(['logistic-order', row.id]);
  }
}
