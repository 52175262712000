import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-material-css-vars";
const base_url = environment.apiUrl;
const api_key = environment.apiKey;
export class ConfigurationService {
    constructor(http, materialCssVarsService) {
        this.http = http;
        this.materialCssVarsService = materialCssVarsService;
        this.photo = '../../assets/images/aware-logo.png';
        this.url = base_url;
    }
    setTheme() {
        if (localStorage.getItem('currentUser') !== null) {
            this.getConfiguration('photo').subscribe(data => {
                this.photo = data.file;
            });
            this.getConfiguration('theme').subscribe(data => {
                if (data !== undefined) {
                    this.materialCssVarsService.setPrimaryColor(data.color);
                }
            });
        }
        else {
            this.materialCssVarsService.setPrimaryColor('#24049c'); // Aware logo color
        }
        this.materialCssVarsService.setAccentColor('#4caf50');
        this.materialCssVarsService.setContrastColorThresholdAccent('400');
    }
    loadConfigurations() {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'configuration', { headers: header }).map((configurations) => {
            this.configurations = configurations;
            return configurations;
        });
    }
    getConfiguration(page, force) {
        // quando as configurations já foram carregadas
        if (this.configurations && force !== true) {
            return new Observable((observer) => {
                if (this.configurations.length > 0) {
                    const found = this.configurations.find(e => e.page === page);
                    if (found) {
                        observer.next(found.configuration);
                    }
                    else {
                        throw new Error('Not found');
                    }
                }
            });
        }
        else {
            // quando as configurations ainda não foram carregadas
            return this.loadConfigurations().pipe(map((configurations) => {
                const found = configurations.find(e => e.page === page);
                if (found) {
                    return found.configuration;
                }
                else {
                    throw new Error('Not found');
                }
            }));
        }
    }
    setThemeColor(what) {
        this.getConfiguration(what).subscribe(data => {
            if (data !== undefined) {
                this.materialCssVarsService.setPrimaryColor(data.primary);
            }
            else {
                this.materialCssVarsService.setPrimaryColor('#64b5f6');
            }
        }, (error) => {
            this.materialCssVarsService.setPrimaryColor('#64b5f6');
        });
    }
    saveLogoImage(files) {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const formdata = new FormData();
        formdata.append('file', files._files[0]);
        return this.http.request(new HttpRequest('POST', this.url + 'configuration/upload/photo', formdata, { headers: header }));
    }
    saveColor(color) {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(this.url + 'configuration', {
            'page': 'theme',
            'configuration': { 'color': color }
        }, { headers: header });
    }
}
ConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MaterialCssVarsService)); }, token: ConfigurationService, providedIn: "root" });
